import React, { useEffect, useState } from "react";
import { Button, Header, Modal, Tabs, Filter, DropDown } from "../components";
import axios from "../axios";
import useStore from "../store/AppContext";
import { disconnect } from "@wagmi/core";
import { UTILS_ADDRESS, HOSTING } from "../config";
import { getErrorFromContract } from "../service/helper";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import CWARUTILS from "../abi/CWARUTILS.json";
import { Outlet, useNavigate } from "react-router-dom";
import MyNFTCard from "./components/MyNFTCard";
import AddAssets from "./components/AddAssets";

export default function Profile() {
  const [mynfts, setMynfts] = useState();
  const [history, setHistory] = useState();
  const [unstakeNft, setUnstakeNft] = useState();
  const [claimNft, setClaimNft] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [errorMessage, setErrorMessage] = useState();
  const { address, isConnecting, isDisconnected } = useAccount();
  const [filter, setFilter] = useState({ offset: 0, limit: 10 });
  const {
    baseNfts,
    user,
    signin,
    basePlans,
    saveToLocalStorage,
    setMessege: stateSetMessege,
    setShowNftAddModal,
  } = useStore();
  const [progress, setProgress] = useState(false);
  const [currency, setCurrency] = useState();
  const [changeAddress, setChangeAddress] = useState({
    showChangeAddress: false,
  });
  const [addAsset, setAddAssets] = useState({
    visible: false,
    activeTab: 0,
    activeStep: 0,
  });

  const nav = useNavigate();

  useEffect(() => {
    _getMyNfts();
  }, [address]);

  const _getMyNfts = () => {
    axios.get(`/user/mynfts/${address}`).then((res) => {
      // console.log('data:', res.data)
      if (res.data.status) {
        setMynfts(res.data.data);
      } else {
        setMynfts();
      }
    });
  };

  const _getHistory = () => {
    axios
      .get(`/user/history`, {
        params: {
          address,
          limit: filter?.limit,
          offset: filter?.limit * filter?.offset,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setHistory(res.data.data);
        } else {
          setHistory();
        }
      });
  };

  useEffect(() => {
    _getHistory();
  }, [filter, address]);

  // UNSTAKE
  // console.log('unstakeNft', unstakeNft)
  const { config: configTransferToken, error } = usePrepareContractWrite({
    enabled: unstakeNft ? true : false,
    address: UTILS_ADDRESS,
    abi: CWARUTILS,
    functionName: unstakeNft?.isFnft ? "unstakeFractionalNFT" : "unstakeNFT",
    args: [unstakeNft?.nft_id ?? null],
  });
  const {
    data,
    isLoading,
    isSuccess: transferSuccess,
    write: unStake,
  } = useContractWrite({
    ...configTransferToken,
    onSuccess: async (data) => {
      setProgress(true);
      // console.log('Success', data)
      //unstake
      const trx = {
        contract: UTILS_ADDRESS,
        type: "unstaking",
        transactionHash: data.hash,
        nft_id: unstakeNft?.nft_id,
        base_nft_id: unstakeNft?.base_nft_id,
        stake_plan: unstakeNft?.staked_plan,
        name: baseNfts[unstakeNft?.base_nft_id - 1]?.["name"],
        series: baseNfts[unstakeNft.base_nft_id - 1]?.["current_series"],
        from: UTILS_ADDRESS,
        to: address,
        isFnft: unstakeNft?.isFnft, //or true,
        amount: unstakeNft?.amount, //or stake_amount,
        sponsor_id: user?.sponsor_id,
        user_id: user?.user_id,
        wallet_address: address,
        timestamp: Math.floor(Date.now() / 1000),
        status: "pending",
      };
      // console.log('trx////// ', trx)
      await axios
        .post("user/add_transaction", trx)
        .then((res) => {
          // console.log(res.data)
        })
        .catch((error) => {
          // console.log('error:', error)
        });

      // console.log('Await for blockchain confirmation...')
      await data.wait();

      axios
        .post("user/unstake", { transactionHash: data.hash })
        .then((res) => {
          // console.log('UNSTAKING SUCCESS')
          // console.log(res.data)
          setUnstakeNft();
          stateSetMessege({
            title: `Unstaking success`,
            render: () => (
              <p>{`#${unstakeNft?.nft_id} ${
                baseNfts[unstakeNft?.base_nft_id - 1]?.["name"]
              } unstaked successfully`}</p>
            ),
            type: "success",
          });
          _getMyNfts();
          setProgress(false);
        })
        .catch((error) => {
          // console.log('error:', error)
          setProgress(false);
        });
    },
    onError(error) {
      // console.log('Error', error)
      setProgress(false);
    },
  });

  // Contract Error handling
  useEffect(() => {
    var ERROR = getErrorFromContract(error);
    setErrorMessage(ERROR?.replace("execution reverted: ", ""));
  }, [error]);

  const claimReward = () => {
    if (!claimNft || progress) return;
    setProgress(true);

    var claimReward = {
      address,
      nft_id: claimNft.nft_id,
      base_nft_id: claimNft.base_nft_id,
      base_nft_name: baseNfts[claimNft.base_nft_id - 1].name,
      nft_series: baseNfts[claimNft.base_nft_id - 1].current_series,
      sponsor_id: claimNft.sponsor_id,
      user_id: claimNft.user_id,
    };
    axios
      .post("user/claim-reward", claimReward)
      .then((res) => {
        if (res.data.status) {
          // console.log('claim-reward', res.data)
          setProgress(false);
          setClaimNft();
          setErrorMessage(res.data.message);
          stateSetMessege({
            title: `Reward claimed`,
            render: () => (
              <p>{`Reward claimed successfully on nft #${claimNft.nft_id} ${
                baseNfts[claimNft.base_nft_id - 1].name
              }`}</p>
            ),
            type: "success",
          });
        }
      })
      .catch((error) => {
        // console.log('claim-reward error:', error.response.data)
        setErrorMessage(error.response.data.message.webServiceMessage);
        setProgress(false);
        stateSetMessege({
          title: `Reward claiming error`,
          render: () => <p>{error.response.data.message.webServiceMessage}</p>,
          type: "error",
        });
      });
  };

  // // CLAIM TEST
  // const { config: configClaim, errorClaim } = usePrepareContractWrite({
  //   enabled: selectedNft ? true : false,
  //   address: UTILS_ADDRESS,
  //   abi: CWARUTILS,
  //   functionName: 'claimReward',
  //   args: [address, selectedNft?.nft ?? null],
  // })

  // const {
  //   dataClaim,
  //   isLoadingClaim,
  //   isSuccess: claimSuccess,
  //   write: claimReward_,
  // } = useContractWrite({
  //   ...configClaim,
  //   onSuccess(data) {
  //   console.log('Success', data)
  //   },
  //   onError(error) {
  //   console.log('Error', error)
  //   },
  // })

  const onChageCurrency = () => {
    if (!currency) return;
    setProgress(true);
    axios
      .post(`/user/updatecurrency`, {
        address,
        currency,
      })
      .then((res) => {
        // console.log('data:', res.data)
        if (res.data.status) {
          setProgress(false);
          // signin({ ...user, default_payment: currency })
          signin({ ...user, default_payment: currency });
          saveToLocalStorage("@user", { ...user, default_payment: currency });
          setCurrency();
          stateSetMessege({
            title: `Currency changed`,
            render: () => <p>{`Currency changed to ${currency}`}</p>,
            type: "success",
          });
        }
      });
  };

  function _refreshBalance() {
    if (!mynfts) return;
    setProgress(true);
    axios
      .post(`/user/reloadNftbalance`, {
        address,
        nft_ids: mynfts.map((_) => _.nft_id),
      })
      .then((res) => {
        // console.log('data:', res.data)
        _getMyNfts();
        setProgress(false);
      });
  }

  return (
    <>
      <Outlet />
      <div
        id="sidebar"
        className="bg-light_bg dark:bg-dark_bg bg-no-repeat bg-cover sm:pb-[15%]"
      >
        <Header />

        <div className="backgroundProfile md:flex items-center sm:px-[10%] p-4 text-slate-600 dark:text-[#c9c9c9]">
          <img src="./profile.png" className="h-[100px]" />
          <div className="sm:flex justify-between w-[100%] items-center">
            <div className="ml-4">
              {user && <p className="font-bold text-2xl">{user.username}</p>}
              <p className="text-sm ">{address}</p>

              {user && (
                <a
                  className="text-[#0E7FB8]"
                  href="#"
                  onClick={() =>
                    setChangeAddress({
                      ...changeAddress,
                      showChangeAddress: true,
                    })
                  }
                >
                  Change address
                </a>
              )}

              {user && (
                <p className="text-sm">
                  Joined :{" "}
                  {new Date(user.joined_at * 1000).toLocaleDateString()}
                </p>
              )}
            </div>

            <div>
              <div className="flex">
                <div>
                  <p className="text-slate-600">Add assets </p>
                  <button
                    onClick={() => setAddAssets({ ...addAsset, visible: true })}
                    className="flex items-center border-[3px] border-white dark:bg-[#333] dark:border-0 rounded-[10px] py-[10px] pl-4 pr-8 text-lg mt-[12px] app-shadow"
                  >
                    <span className="material-symbols-outlined text-orange-600">
                      account_balance_wallet
                    </span>
                    <span className="ml-2">Add to wallet</span>
                  </button>
                </div>
                {user && (
                  <div className="ml-4">
                    <p className="text-slate-600">Default currency</p>
                    <DropDown
                      placeholder={user?.default_payment ?? "Select currency"}
                      data={[
                        { title: "USDT", value: "USDT" },
                        { title: "USDC", value: "USDC" },
                      ]}
                      onChage={(val) =>
                        user?.default_payment == val ? null : setCurrency(val)
                      }
                      error={"no"}
                      icon={true}
                    />
                  </div>
                )}
              </div>

              {user && (
                <button
                  className="flex items-center text-red-500 py-2 md:float-right"
                  onClick={async () => {
                    await disconnect();
                    // console.log('clean user local storage', address)
                    localStorage.removeItem("@user");
                    signin(null);
                    nav("/");
                  }}
                >
                  <span className="material-symbols-outlined mr-2">
                    chip_extraction
                  </span>
                  Logout
                </button>
              )}
            </div>
          </div>
        </div>

        {/* MY STAKES */}
        <div className="sm:px-[10%] relative mt-6">
          <Tabs
            setActiveTab={(tabIndex) => setActiveTab(tabIndex)}
            activeTab={activeTab}
          />

          {/*  REFRESH BUTTON */}
          <button
            className="top-0 right-[10%] absolute bg-white p-2 flex transition-all duration-200 bg-gradient-to-l hover:from-blue-200 from-0% hover:via-blue-100 via-30% to-transparent to-100% border-[1px] hover:border-blue-400 hover:text-[#0E7FB8] rounded-full"
            onClick={() => _refreshBalance()}
          >
            <span
              className={`hover:rotate-180 material-symbols-outlined ${
                progress ? "animate-spin" : ""
              }`}
            >
              refresh
            </span>
            {!progress && <p className="ml-1 phone:hidden">Refresh Balance</p>}
          </button>

          {/* TAB ONE */}
          {activeTab == 0 && (
            <div className="flex row flex-wrap justify-center md:justify-start">
              {mynfts &&
                mynfts.map((nft, key) => (
                  <MyNFTCard
                    key={"MyNFTCard_" + key}
                    baseNfts={baseNfts}
                    onCardClick={() => null}
                    {...nft}
                  />
                ))}
              {/* ADD NFT CARD */}
              <div className="">
                <div
                  className="scale-95 hover:scale-100 px-10 py-[70px] rounded-[30px] mx-4 bg-gray-100 dark:hover:bg-[#444] dark:bg-[#333] cursor-pointer border-[5px]"
                  onClick={() => setShowNftAddModal(true)}
                >
                  <span className="material-symbols-outlined text-6xl text-gray-400 ml-3">
                    add
                  </span>
                  <p className="m-auto text-gray-400 my-4">Add new NFT</p>
                </div>
              </div>
            </div>
          )}

          {/* TAB TWO */}
          {/* Filter */}
          {activeTab == 1 && (
            <div className="overflow-x-scroll scrollbar-hide">
              {/* <div>
    <Filter
      length={filter?.offset+1 > 20 ? filter?.offset+1 : 20}
      offset={filter.offset}
      goToPrevious={(val) => setFilter({ ...filter, offset: val })}
      goToFirst={(val) => setFilter({ ...filter, offset: val })}
      goToLast={(val) => setFilter({ ...filter, offset: val })}
      goToNext={(val) => setFilter({ ...filter, offset: val })}
    />
    </div> */}

              <div className="bg-white rounded-[25px] app-shadow min-w-[900px] dark:bg-[#0d0d0d50] text-slate-600 dark:text-[#c9c9c9] ">
                {/* HEADER */}
                <div className="flex row items-center justify-between px-10 py-4 text-slate-500">
                  <p className="flex-1 text-left text-sm">NFT</p>
                  <p className="flex-1 text-left text-sm">#ID NAME</p>
                  <p className="flex-1 text-left text-sm">FRACTION</p>
                  <p className="flex-1 text-left text-sm">STAKING DATE</p>
                  <p className="flex-1 text-left text-sm">TERM</p>
                  <p className="flex-1 text-left text-sm">MATURITY</p>
                  <p className="flex-1 text-left text-sm">VESTING DATE</p>
                  <div className="hidden md:flex w-[25%]"></div>
                </div>
                {mynfts && mynfts.length > 0 ? (
                  mynfts
                    .filter((_) => _.is_staked == true)
                    .map((nft, key) => {
                      const staked_plan = basePlans?.find(
                        (_) => _.plan_id == nft.staked_plan
                      );
                      const due_date =
                        (nft.staked_time + staked_plan?.["duration"]) * 1000;
                      const btnEnabled =
                        due_date > new Date().getTime() && nft.staked_plan != 1;
                      const year = 60 * 60 * 24 * 365;
                      const diff_in_years =
                        (new Date().getTime() / 1000 - nft.staked_time) / year;
                      const vesting_duration = Math.ceil(diff_in_years);
                      const vesting_date = new Date(
                        (nft.staked_time + vesting_duration * year) * 1000
                      );
                      const thumb_path =
                        nft.isFnft === true
                          ? `/f_thumbs/${nft?.name
                              .replace(" ", "-")
                              .toLowerCase()}-f.png`
                          : `/thumbs/${nft?.name
                              .replace(" ", "-")
                              .toLowerCase()}.png`;
                      return (
                        <div key={"list_" + key}>
                          <div className="flex row items-center justify-between border-t-[1px] dark:border-[#333] border-slate-100 px-10">
                            <div className="flex-1">
                              <img
                                className="rounded-lg w-14 ml-0 m-4 hover:scale-105 transition"
                                // src={baseNfts[nft.base_nft_id - 1].url}
                                // src="/nft_alt.png"
                                src={thumb_path}
                                // onClick={props?.onCardClick}
                              />
                            </div>
                            <p className="flex-1 text-left">
                              #{nft.nft_id}{" "}
                              {nft.isFnft === true ? (
                                <span className="text-[#0E7FB8]">
                                  {baseNfts[nft.base_nft_id - 1].name} F-Token
                                </span>
                              ) : (
                                baseNfts[nft.base_nft_id - 1].name
                              )}
                            </p>
                            <p className="flex-1 text-left">
                              {nft.staked_amount + "/" + nft.amount}
                            </p>
                            <p className="flex-1 text-left">
                              {new Date(
                                nft.staked_time * 1000
                              ).toLocaleDateString()}
                            </p>
                            <p className="flex-1 text-left">
                              {
                                basePlans.find(
                                  (_) => _.plan_id == nft.staked_plan
                                )?.["title"]
                              }
                            </p>
                            <p className="flex-1 text-left">
                              {nft?.staked_plan == 1
                                ? "Released"
                                : new Date(
                                    (nft.staked_time +
                                      basePlans.find(
                                        (plan) =>
                                          plan.plan_id == nft?.staked_plan
                                      )?.duration) *
                                      1000
                                  ).toLocaleDateString()}
                            </p>{" "}
                            <p className="flex-1 text-left">
                              {nft?.staked_plan == 1
                                ? "Vested"
                                : vesting_date.toLocaleDateString()}
                            </p>
                            <div className="hidden md:flex w-[25%]">
                              <Button
                                title="Unstake"
                                onClick={() => {
                                  // console.log('unStaking...')
                                  setErrorMessage();
                                  setUnstakeNft(nft);
                                }}
                                type="warn"
                                disabled={btnEnabled}
                              />

                              <Button
                                title="Claim"
                                className="sm:ml-2 sm:mt-0 mt-2"
                                onClick={() => {
                                  // console.log('claiming...')
                                  setErrorMessage();
                                  setClaimNft(nft);
                                }}
                                type="success"
                                disabled={btnEnabled}
                              />
                            </div>
                          </div>
                          {/* MOBILE UI */}
                          <div className="flex justify-center items-center sm:hidden mb-2">
                            <Button
                              title="Unstake"
                              onClick={() => {
                                // console.log('unStaking...')
                                setErrorMessage();
                                setUnstakeNft(nft);
                              }}
                              disabled={btnEnabled}
                            />
                            <Button
                              title="Claim Reward"
                              className="ml-2"
                              onClick={() => {
                                // console.log('claiming...')
                                setErrorMessage();
                                setClaimNft(nft);
                              }}
                              type="success"
                              disabled={btnEnabled}
                            />
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <p className="p-4 text-center text-slate-400 m-auto">
                    No Stakes!
                  </p>
                )}
              </div>
            </div>
          )}
        </div>

        {/* HISTORY */}
        <div className="sm:px-[10%] mt-16">
          <>
            <div>
              <Filter
                title="Transactions"
                length={Math.ceil(history?.totalCount / filter?.limit + 1)}
                activeIndex={filter.offset}
                goToPrevious={(val) => setFilter({ ...filter, offset: val })}
                goToFirst={(val) => setFilter({ ...filter, offset: 0 })}
                goToLast={(val) => setFilter({ ...filter, offset: val - 1 })}
                goToNext={(val) => setFilter({ ...filter, offset: val })}
                onChageLimit={(val) => setFilter({ ...filter, limit: val })}
              />
            </div>

            <div className="bg-white rounded-[25px] app-shadow overflow-x-scroll scrollbar-hide dark:bg-[#0d0d0d50] text-slate-600 dark:text-[#c9c9c9]">
              {/* HEADER */}
              <table className="w-full">
                <thead>
                  <tr className="text-left text-slate-400 p-4 border-b-[1px] border-b-slate-100 dark:border-b-slate-700">
                    <th className="flex-1 text-left text-sm"></th>
                    <th className="flex-1 text-left text-sm">Name</th>
                    <th className="flex-1 text-left text-sm">Amount</th>
                    <th className="flex-1 text-left text-sm">Address</th>
                    <th className="flex-1 text-left text-sm">Type</th>
                    <th className="flex-1 text-left text-sm">Block</th>
                    <th className="flex-1 text-left text-sm">Time</th>
                    <th className="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {history?.history &&
                    history.history.map((trx, key) => {
                      //  console.log('=====', trx)
                      return (
                        <tr key={"_history" + key}>
                          <td className="text-center">
                            <span
                              className={`material-symbols-outlined ${
                                address == trx.from
                                  ? "text-[#e9c732]"
                                  : "text-[#3fee82]"
                              }`}
                            >
                              {address == trx.from
                                ? "north_east"
                                : "call_received"}
                            </span>
                          </td>
                          <td>
                            <p>{trx.name}</p>
                          </td>
                          <td>
                            <p>
                              {trx.name == "USDT" || trx.name == "USDC"
                                ? parseFloat(trx.amount).toFixed(2)
                                : trx.amount}
                            </p>
                          </td>
                          <td>
                            <a
                              href={`https://${
                                HOSTING == "PRODUCTION" ? "" : "mumbai."
                              }polygonscan.com/address/${
                                address == trx.to ? trx.from : trx.to
                              }`}
                              className="text-[#0E7FB8]"
                              target="_blank"
                            >
                              {address == trx.to ? trx.from : trx.to}
                            </a>
                          </td>
                          <td>
                            <p className="text-slate-400">{trx.type}</p>
                          </td>
                          <td>
                            <a
                              href={`https://${
                                HOSTING == "PRODUCTION" ? "" : "mumbai."
                              }polygonscan.com/tx/${trx.transactionHash}`}
                              className="text-[#0E7FB8]"
                              target="_blank"
                            >
                              <span className="material-symbols-outlined">
                                deployed_code
                              </span>
                            </a>
                          </td>
                          <td>
                            <p>
                              {new Date(trx.timestamp * 1000).toLocaleString()}
                            </p>
                          </td>
                          <td className="text-center">
                            <span
                              className={`material-symbols-outlined ${
                                trx.status == "success"
                                  ? "text-green-500"
                                  : trx.status == "failed" ? "text-red-400" : "text-orange-400"
                              }`}
                            >
                              {trx.status == "success" ? "done" :  trx.status == "failed" ? "error" : "pending"}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {history?.history.length < 1 && (
                <p className="p-4 text-center text-slate-400 m-auto">
                  No history!
                </p>
              )}
            </div>
          </>
        </div>
      </div>

      <Modal
        visible={claimNft}
        onSubmit={() => claimReward?.(claimNft)}
        title="Confirm claiming"
        onClose={() => setClaimNft()}
        onSubmitTitle="Confirm"
        loading={progress}
        buttonType="success"
      >
        {claimNft && (
          <div className="p-6 block md:min-w-[600px]">
            <p className="text-lg mx-6">
              Are you sure you want to claim reward on NFT #{claimNft.nft_id}
            </p>
            {errorMessage && (
              <p className="p-2 my-2 text-lg bg-[#f5691810] rounded-full text-[#f56918] mx-6 px-4">
                {errorMessage}
              </p>
            )}
          </div>
        )}
      </Modal>

      <Modal
        visible={unstakeNft}
        onSubmit={() => {
          setProgress(true);
          unStake?.();
        }}
        title="Confirm unstaking"
        onClose={() => setUnstakeNft()}
        onSubmitTitle="Confirm"
        loading={progress}
        buttonType="warn"
        buttonDisabled={errorMessage ? true : false}
      >
        {unstakeNft && (
          <div className="p-6 block md:min-w-[600px]">
            <p className="text-lg mx-6">
              Are you sure you want to unstake NFT #{unstakeNft.nft_id}
            </p>
            {errorMessage && (
              <p className="p-2 my-2 text-lg bg-[#f5691810] rounded-full text-[#f56918] mx-6 px-4">
                {errorMessage}
              </p>
            )}
          </div>
        )}
      </Modal>

      {/* CHANGE CURRENCY */}
      <Modal
        visible={currency ? true : false}
        onSubmit={() => onChageCurrency?.()}
        title="Confirm currency change"
        onClose={() => setCurrency()}
        onSubmitTitle="Confirm"
        loading={progress}
        buttonType="warn"
      >
        {currency && (
          <div className="p-6 block md:min-w-[600px]">
            <p className="text-lg mx-6">
              Change currency from {user?.default_payment} to {currency}{" "}
            </p>
          </div>
        )}
      </Modal>

      {/* CHANGE ADDRESS MODAL */}
      <Modal
        visible={changeAddress?.showChangeAddress}
        onSubmit={
          () => {
            if (!changeAddress?.newAddress) {
              setChangeAddress({
                ...changeAddress,
                error: "new address is required!",
              });
              return;
            }
            axios
              .post(`/user/update-address`, {
                user_id: user?.user_id,
                new_address: changeAddress.newAddress,
              })
              .then((res) => {
                if (res.data.status) {
                  signin({
                    ...user,
                    address: changeAddress.newAddress,
                  });
                  setChangeAddress({
                    ...changeAddress,
                    showChangeAddress: false,
                  });
                } else {
                  setChangeAddress({
                    ...changeAddress,
                    error: res.data?.message,
                  });
                }
              });
          }
          // setChangeAddress({
          //   ...changeAddress,
          //   showChangeAddress: false,
          // })}
        }
        title="Change address"
        onClose={() =>
          setChangeAddress({
            ...changeAddress,
            showChangeAddress: false,
          })
        }
        onSubmitTitle="Change"
        loading={false}
      >
        <div className="p-6 block md:min-w-[600px]">
          <p className="text-lg mx-4">New address</p>
          <input
            placeholder={address}
            className="app-shadow p-3 m-4 w-[95%] rounded-lg focus:outline-none focus:ring focus:ring-slate-300 text-lg dark:bg-[#333]"
            value={changeAddress?.newAddress ?? ""}
            onChange={(e) => {
              // user_id', 'new_address
              setChangeAddress({
                ...changeAddress,
                newAddress: e.target.value,
              });
            }}
            type="text"
          />
          {changeAddress?.error && (
            <p className="bg-[#f5691820] text-[#f56918] p-4 rounded-xl mx-4">
              {changeAddress.error}
            </p>
          )}
          <p className="text-sm m-4">
            When changing your wallet address,
            <b> exercising caution is of utmost importance </b> as it directly
            impacts the accuracy of your profile information. Take the necessary
            time to{" "}
            <b> diligently verify and double-check the new wallet address </b>{" "}
            you enter for complete accuracy. This critical step ensures seamless
            transactions and secure transfers of your funds and rewards.{" "}
            <b>
              Failing to carefully review and confirm the modified wallet
              address may result in irreversible consequences, including
              potential loss of your valuable digital assets, staking rewards,
              and any associated commissions.
            </b>{" "}
            Prioritize accuracy and thoroughness to safeguard your assets and
            maintain a smooth experience on our platform.
          </p>
        </div>
      </Modal>

      <AddAssets addasset={addAsset} setaddassets={setAddAssets} />
    </>
  );
}
