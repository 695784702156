import React from 'react';
import { Navigate } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div
            className="fixed left-0 top-0 w-full h-full flex flex-col items-center justify-center"
        >
            <img
                src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
                alt="404"
                className="mt-20 mb-8 h-48 md:h-64"
            />
            <h1 className="text-5xl md:text-7xl font-bold mb-8">404 PAGE</h1>
            <p className="text-lg md:text-xl mb-4 pb-20">
                The page you were looking for could not be found
            </p>
            <a
                href="/"
                className="bg-slate-200 text-black text-xl px-6 py-3 rounded-full font-bold shadow-lg transition duration-300 hover:translate-y-[-5px]"
            >
                Back to Home
            </a>
        </div>
    );
};

export default NotFoundPage;
