import React, { useEffect, useState } from "react";
import useStore from "../store/AppContext";
// import WalletConnect from '../service/WalletConnect'
import { useWeb3Modal, Web3Button } from "@web3modal/react";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import { disconnect } from "@wagmi/core";

import axios from "../axios";
import axios_ from "axios";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import {
  BACKOFFICE_API,
  BACKOFFICE_API_KEY,
  NFT_ADDRESS,
  SESSION_TIME,
  UTILS_ADDRESS,
  FNFT_ADDRESS,
  USERSIDEURL,
  HOSTING,
} from "../config";
import Button from "./Button";
import Switch from "./Switch";
import Prompt from "./Prompt";
import CWARNFT from "../abi/CWARNFT.json";
import CWARFRACTIONAL from "../abi/CWARFRACTIONAL.json";

import { useIdleTimer } from "react-idle-timer";

const regForm = {
  referrel_id: "",
  username: "",
  email: "",
  password: "",
  confirm_password: "",
};
const Header = (props) => {
  const [form, setForm] = useState(regForm);
  const [error, setError] = useState(regForm);
  const [showNav, setShowNav] = useState(false);
  const [loading, setLoading] = useState(false);
  const [affiliate, setAffiliate] = useState(false);
  const [acceptT_C, setAcceptT_C] = useState(false);
  const [add_new_address, set_add_new_address] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const state = useStore();
  // console.log('STORE', state)
  // removeFromCart(product);
  const { address, isConnecting, isDisconnected } = useAccount();
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const nav = useNavigate();
  const [nftId, setNftId] = useState();

  const onIdle = () => {
    if (!address) return;
    // SIGN_OUT
    localStorage.removeItem("@user");
    disconnect?.();
    state.signin(null);
    pause(); // session pause hook from react-idle-timer

    state?.setMessege({
      title: `Session timeout`,
      render: () => <p>{`Connect wallet again`}</p>,
      type: "error",
    });
    setShowSessionModal(true);
  };
  const { reset, pause, resume, isIdle, getActiveTime } = useIdleTimer({
    onIdle,
    timeout: SESSION_TIME,
  });

  // CHECK IS ALREADY APPROVED
  const {
    data: isAlreadyApproved,
    isError,
    refetch,
    isLoadingApproval,
  } = useContractRead({
    enabled: address ? true : false,
    address: NFT_ADDRESS,
    abi: CWARNFT,
    functionName: "isApprovedForAll",
    args: [address, UTILS_ADDRESS],
  });

  // CHECK IS ALREADY APPROVED FNFT
  const {
    data: isAlreadyApprovedFNFT,
    isError: isErrorFNFT,
    refetch: f_refetch,
    isLoadingApproval: isLoadingApprovalFNFT,
  } = useContractRead({
    enabled: address ? true : false,
    address: FNFT_ADDRESS,
    abi: CWARFRACTIONAL,
    functionName: "isApprovedForAll",
    args: [address, UTILS_ADDRESS],
  });

  useEffect(() => {
    if (state?.showApproval != "DO_NOT_SHOW_AGAIN") {
      state?.setShowApproval(isAlreadyApproved || isAlreadyApprovedFNFT);
    }
  }, [isAlreadyApproved, isAlreadyApprovedFNFT]);

  useEffect(() => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      if (state.proccessing) {
        e.preventDefault();
        if (e) {
          e.returnValue = ""; // Legacy method for cross browser support
        }
        return ""; // Legacy method for cross browser support
      }
    };
  }, [state.proccessing]);
  // UPDATE

  // DIS-APPROVAL FNFT ACCESS
  const { config: configApprovelFNFT } = usePrepareContractWrite({
    // enabled: !isAlreadyApproved,
    address: FNFT_ADDRESS,
    abi: CWARFRACTIONAL,
    functionName: "setApprovalForAll",
    args: [UTILS_ADDRESS, false],
  });

  const {
    data: dataFNFT,
    isLoading: isLoadingFNFT,
    isSuccess: successApprovelFNFT,
    write: removeApprovelFNFT,
  } = useContractWrite({
    ...configApprovelFNFT,
    onSuccess: async (data) => {
      await data.wait();
      console.log("Success dis-approval, going to stake FNFT", data);
      f_refetch?.();
      if (!isAlreadyApproved) {
        state.setShowApproval(false);
      }
      setLoading(false);
    },
    onError(error) {
      console.log("Error approveFNFT --->", error);
      setLoading(false);
    },
  });

  // DIS-APPROVAL NFT ACCESS
  const { config: configApprovel } = usePrepareContractWrite({
    enabled: isAlreadyApproved ? true : false,
    address: NFT_ADDRESS,
    abi: CWARNFT,
    functionName: "setApprovalForAll",
    args: [UTILS_ADDRESS, false],
  });

  const {
    data,
    isLoading,
    isSuccess: transferSuccess,
    write: removeApprovelNFT,
  } = useContractWrite({
    ...configApprovel,
    onSuccess: async (data) => {
      await data.wait();
      // console.log('Success remove_approvel', data)
      refetch?.();
      if (!isAlreadyApprovedFNFT) {
        state.setShowApproval(false);
      }
      if (isAlreadyApprovedFNFT) {
        removeApprovelFNFT?.();
      }
      setLoading(false);
    },
    onError(error) {
      setLoading(false);
      // console.log('Error approve', error)
    },
  });

  useEffect(() => {
    // console.log('address----->', address)
    // if (address) {
    address && _checkUserExists();
    // } else {
    //   // open() // open Web3Modal
    // }

    // Sign out if user on store
    // if (isDisconnected && state?.user) {
    //   console.log('clean user local storage')
    //   localStorage.removeItem('@user')
    //   state.signin(null)
    //   nav('/')
    //   close()
    // }
    if (state?.user && address) {
      if (state?.user?.address == "") {
        set_add_new_address({ ...add_new_address, address, show: true });
        return;
      }

      if (state?.user?.address !== address) {
        disconnect?.();
        address &&
          // alert(
          //   `wallet address mismatch. Please use ${state?.user?.address} to login`,
          // )
          state?.setMessege({
            title: `Change account`,
            render: () => (
              <p>{`Wallet address mismatch. Please use ${state?.user?.address} to login`}</p>
            ),
            type: "error",
          });
      }
      // console.log('clean user local storage, wallet address mismatch, logged out!')
      // localStorage.removeItem('@user')
      // state.signin(null)
    }
    if (address) {
      reset?.(); // session reset hook from react-idle-timer
      setShowSessionModal(false);
    }

    _getBaseCurrencyValue();
    address && refetch?.(); // refetch approvals
  }, [address]);

  const _getBaseCurrencyValue = async () => {
    if (state.baseCurrencyValue != 0) return;
    axios
      .get(`common/getCurrentprice/${state.user?.default_payment ?? "USDT"}`)
      .then((res) => {
        // console.log('on getCurrentprice----->', res.data)
        if (res.data.status) {
          state.setBaseCurrencyValue(res.data.data);
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  useEffect(() => {
    // stores initial states if null
    if (!state.baseNfts) {
      state.getBaseNfts();
    }
    if (!state.basePlans) {
      state.getBasePlans();
    }
  }, []);

  const onChange = (e) => {
    e.preventDefault();

    setForm({ ...form, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const onError = (name, value) => {
    // console.log(name, value)
    setError({ ...error, [name]: value });
  };

  const onSubmit = () => {
    var errors = {};
    var isValid = true;
    setLoading(true);
    Object.entries(form).map((_, k) => {
      if (form[_[0]] == "") {
        Object.assign(errors, { [_[0]]: _[0] + " is required!" });
        isValid = false;
      }
    });
    if (form.password !== form.confirm_password) {
      isValid = false;
      Object.assign(errors, { confirm_password: "Passwords do NOT match.!" });
    }
    setError(errors);

    if (isValid) {
      // console.log('CHECK REFEREL ID ')
      // console.log('regiser... CALLING MLM SIGN UP API ')

      const payload = {
        email: form.email,
        pswd: form.password,
        sponsor_referral_code: form.referrel_id,
        user_name_entry: form.username,
        wallet_address: address,
        affiliate,
        usertype: affiliate ? "affiliate" : "customer",
      };

      var config = {
        method: "post",
        url: BACKOFFICE_API + "/api/web/nft/register-user",
        headers: {
          "api-key": BACKOFFICE_API_KEY,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };
      axios_(config)
        .then(function (response) {
          if (response.data.status) {
            // state.saveToLocalStorage('@user', form)
            // state.signin({ ...form, address })
            // state.setShowRegModal(false)
            _onSignin();
          }
          // console.log(JSON.stringify(response.data))
        })
        .catch(function (error) {
          // console.log("------------->", error.response?.data?.error?.description);
          setError({
            confirm_password: error.response?.data?.error?.description,
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const _checkUserExists = () => {
    if (state.user || !address) return;
    setLoading(true);
    axios
      .post(`/user/loginWithaddress`, { address }, { withCredentials: true })
      .then((res) => {
        // console.log('on connect Metamask----->', res.data)
        if (res.data.status) {
          state.saveToLocalStorage("@user", res.data.data);
          state.signin(res.data.data);
          setLoading(false);
          // state.saveToLocalStorage('@user', res.data.data)
          // state.signin(res.data.data)
        } else {
          // state.setShowRegModal(true)
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log(error)
        setLoading(false);
      });
  };

  const _addNftdetails = () => {
    if (!address) return;
    if (!nftId) {
      setError({ ...error, add_nft: "NFT Id is required!" });
      return;
    }
    setLoading(true);
    axios
      .post(`/user/addNftdetails`, { address, nft_id: nftId })
      .then((res) => {
        // console.log('_addNftdetails----->', res.data)
        if (res.data.status) {
          state?.setMessege({
            title: `NFT Added`,
            render: () => (
              <p>{`NFt #${nftId} successfully added to your account`}</p>
            ),
            type: "success",
          });
          setLoading(false);
          state.setShowNftAddModal(false);
        } else {
          // state.setShowRegModal(true)
          setLoading(false);
          setError({ ...error, add_nft: res.data.message });
        }
      })
      .catch((error) => {
        // console.log(error)
        setLoading(false);
      });
  };

  const _onSignin = () => {
    var username = form?.username;
    var password = form?.password;
    if (!username || !password) {
      setError({
        username: !username ? "username is required!" : "",
        password: !password ? "password is required!" : "",
      });
      return;
    }
    setLoading(true);

    axios
      .post(
        `/user/signIn`,
        {
          username: username,
          password: password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        // console.log('responce ----->', res.data)
        if (!res.data.data) {
          // console.log('on signin ----->', res.data?.message)
          setError({ password: res.data?.message });
          setLoading(false);
        } else {
          // console.log('on signin success ----->', res.data.data)
          state.saveToLocalStorage("@user", res.data.data);
          state.signin(res.data.data);
          state.setShowSignModal(false);
          state.setShowRegModal(false);
          setLoading(false);
        }
      });
  };

  // async function test() {
  //   const tokenAddress = '0xda5b73146ef49b64aa272616ae1ad3df9df54e23'
  //   const tokenSymbol = 'FEL'
  //   const tokenDecimals = 18
  //   const tokenImage = 'http://placekitten.com/200/300'
  //   const type = 'ERC20'

  //   try {
  //     // wasAdded is a boolean. Like any RPC method, an error may be thrown.
  //     const wasAdded = await window.ethereum?.request({
  //       method: 'wallet_watchAsset',
  //       params: {
  //         type: type, // Initially only supports ERC20, but eventually more!
  //         options: {
  //           address: tokenAddress, // The address that the token is at.
  //           symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
  //           decimals: tokenDecimals, // The number of decimals in the token
  //           image: tokenImage, // A string url of the token logo
  //         },
  //       },
  //     })

  //     if (wasAdded) {
  //       console.log('Thanks for your interest!')
  //     } else {
  //       console.log('Your loss!')
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // THEME SWICH
  const [isDarkTheme, setIsDarkTheme] = useState(
    document.documentElement.classList.contains("dark")
  );

  function switchTheme() {
    const currentTheme = localStorage.getItem("color-theme") || "light";
    const newTheme = currentTheme === "light" ? "dark" : "light";
    document.documentElement.classList.toggle("dark", newTheme === "dark");
    localStorage.setItem("color-theme", newTheme);
    setIsDarkTheme((prevIsDarkTheme) => !prevIsDarkTheme);
  }

  useEffect(() => {
    const currentTheme = localStorage.getItem("color-theme") || "light";
    document.documentElement.classList.toggle("dark", currentTheme === "dark");
    setIsDarkTheme(currentTheme === "dark");
  }, []);

  const _addNewAddress = () => {
    setLoading(true);
    axios
      .post(`/user/update-address`, {
        user_id: state.user?.user_id,
        new_address: add_new_address.address,
      })
      .then((res) => {
        if (res.data.status) {
          let newData = {
            ...state.user,
            address: add_new_address.address,
          };
          state.signin(newData);
          state.saveToLocalStorage("@user", newData);

          set_add_new_address({ show: false });
          setLoading(false);
        } else {
          set_add_new_address({
            ...add_new_address,
            error: res.data?.message,
          });
          disconnect?.();
          setLoading(false);
        }
      });
  };
  return (
    <div>
      <nav
        className={` border-gray-200 dark:bg-gray-[#292929] sm:px-[5%] ${
          HOSTING != "PRODUCTION"
            ? " bg-gradient-to-b from-orange-600 to-transparent"
            : ""
        }`}
      >
        <div className="flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            // href="/"
            className="flex items-center hover:cursor-pointer"
            onClick={state.proccessing ? null : () => nav("/")}
          >
            <img
              src="/plain_logo.png"
              className="w-[100px]"
              alt="CARBON WAR ReFi Logo"
            />
            <div className="">
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-[#c9c9c9] hidden md:block">
                CARBON WAR
              </span>
              <p className="float-right dark:text-[#c9c9c9]">
                {HOSTING != "PRODUCTION" ? "STAGING (DEV)" : "NFT PLATFORM"}
              </p>
            </div>
          </a>

          <button
            onClick={() => setShowNav(!showNav)}
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className={`${showNav ? "" : "hidden"} w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0  md:items-center">
              {[
                { title: "NFTs", path: "" },
                { title: "Stake", path: "stake" },
                { title: "Fractionalize NFT", path: "fractionalize" },
                state?.user || address
                  ? {
                      title: state?.user?.username || "Profile",
                      path: "profile",
                    }
                  : {},
              ].map((page, key) => {
                const isProfile =
                  (state?.user || address) && page.path == "profile";
                return (
                  <li
                    key={"profile_list_" + key}
                    className="flex items-center cursor-pointer"
                  >
                    {isProfile && (
                      <span className="material-symbols-outlined sm:ml-32 dark:text-white">
                        person
                      </span>
                    )}
                    <a
                      // href={'/' + page.path}
                      onClick={
                        state.proccessing ? null : () => nav("/" + page.path)
                        // () => nav('/' + page.path)
                      }
                      className={`block py-2 pl-3 pr-4 ${
                        state.proccessing
                          ? "text-gray-400 dark:text-slate-500 hover:opacity-0"
                          : "text-gray-900"
                      } rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#0E7FB8] md:p-0 dark:text-white md:dark:hover:text-[#0E7FB8] dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent`}
                      // active className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500"
                      aria-current="page"
                    >
                      {page.title}
                    </a>
                  </li>
                );
              })}

              {/* DARK MODE */}
              <li className="flex items-center" onClick={() => switchTheme()}>
                <button >
                  {isDarkTheme ? (
                    <span className="material-symbols-outlined text-white p-1 rounded-lg">
                      light_mode
                    </span>
                  ) : (
                    <span className="material-symbols-outlined p-1 rounded-lg">
                      clear_night
                    </span>
                  )}
                </button>
                <p className="md:hidden dark:text-white">{isDarkTheme ? "Dark Mode" : "Light Mode"}</p>
              </li>
              <li className="flex items-center justify-between bg-[#000] rounded-full">
                {/* <Web3Button label="Connect Wallet" icon="" balance={'20'} /> */}
                <Button
                  onClick={open}
                  title={
                    address
                      ? address.substr(0, 4) + "..." + address.substr(-4)
                      : "Connect Wallet"
                  }
                  rightArrow={address || state?.user ? false : true}
                  className={
                    address || state?.user
                      ? "rounded-none rounded-l-full bg-[#000]"
                      : ""
                  }
                  type="primary"
                />
                {(address || state?.user) && (
                  <button
                    onClick={async () => {
                      await disconnect();
                      // console.log('clean user local storage', address)
                      localStorage.removeItem("@user");
                      state.signin(null);
                    }}
                  >
                    <span className="material-symbols-outlined text-red-600 bg-white p-2 rounded-full">
                      chip_extraction
                    </span>
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Modal
        // visible={false}
        visible={state.showRegModal}
        onSubmit={() => {
          if (affiliate && !acceptT_C) {
            setAcceptT_C(true);
          } else {
            onSubmit();
          }
        }}
        title="Sign up"
        onClose={() => state.setShowRegModal(false)}
        onSubmitTitle={affiliate && !acceptT_C ? "Accept" : "Register"}
        loading={loading}
      >
        <div
          className="py-8 px-6 md:px-12 block  md:min-w-[600px] md:max-w-[600px]"
        >
          <div className="flex justify-between items-center mb-8">
            <label>BECOME AFFILIATE</label>
            <Switch
              enabled={affiliate}
              onClick={() => {
                setAffiliate(!affiliate);
                if (!affiliate) {
                  setAcceptT_C();
                }
              }}
            />
            {/* <button
              className={`${
                affiliate ? 'buttonPrimary' : 'bg-white'
              } rounded-full text-white flex items-center p-1 `}
              onClick={() => {
                setAffiliate(!affiliate)
                if (!affiliate) {
                  setAcceptT_C()
                }
              }}
            >
              {!affiliate && (
                <div className="bg-slate-300 w-[30px] h-[30px] rounded-full self-start"></div>
              )}
              <p className={`px-4 ${affiliate ? 'text-white' : 'text-slate-300'}`}>
                {affiliate ? 'Enabled' : 'Disabled'}
              </p>
              {affiliate && (
                <div className="bg-white w-[30px] h-[30px] rounded-full self-start"></div>
              )}
            </button> */}
          </div>
          {Object.entries(form).map((field, k) => {
            if (affiliate) {
              if (!acceptT_C) return;
            }
            return (
              <div key={"terms_div_" + k} className="mt-4">
                <label>{field[0].toLocaleUpperCase().replace("_", " ")}</label>
                <input
                  className={styles.input}
                  id={field[0]}
                  name={field[0]}
                  placeholder={field[0]}
                  onChange={(e) => onChange(e)}
                  type={field[0].includes("password") ? "password" : field[0]}
                  required={true}
                  autoComplete="new-password"
                />
                {/* <label for={field[0]}>{field[0]}</label> */}
                {error[field[0]] && (
                  <p style={{ color: "#f2355b", fontSize: 14, marginTop: 4 }}>
                    {error[field[0]]}
                  </p>
                )}
              </div>
            );
          })}
          {affiliate && !acceptT_C && (
            <div className="text-sm my-8 overflow-y-scroll h-[400px] text-slate-800">
              <p className="text-lg mb-4 dark:text-slate-300">
                Terms & Conditions
              </p>
              <p className="dark:text-slate-300">
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        visible={state.showSignModal}
        onSubmit={_onSignin}
        title="Sign in"
        onClose={() => state.setShowSignModal(false)}
        onSubmitTitle="Sign in"
        loading={loading}
      >
        <div className="py-8 px-12 block md:min-w-[600px]">
          <>
            <label>Username</label>
            <input
              className={styles.input}
              id={"username"}
              name={"username"}
              placeholder={"Username"}
              onChange={(e) => onChange(e)}
              type={"text"}
              required={true}
            />
            {error?.username && (
              <p style={{ color: "#FC4D95", fontSize: 14, marginTop: 4 }}>
                {error.username}
              </p>
            )}
            <br />
            <br />
            <label>Password</label>
            <input
              className={styles.input}
              id={"password"}
              name={"password"}
              placeholder={"Password"}
              onChange={(e) => onChange(e)}
              type={"password"}
              required={true}
            />

            {error?.password && (
              <p style={{ color: "#FC4D95", fontSize: 14, marginTop: 4 }}>
                {error.password}
              </p>
            )}
          </>
        </div>
      </Modal>

      <Modal
        visible={showSessionModal}
        onSubmit={open}
        title="You have gone idle"
        onClose={() => setShowSessionModal(false)}
        onSubmitTitle="Connect Account"
        loading={loading}
      >
        <div className="py-8 px-12 block md:min-w-[600px]">
          <h3>Are you still here?</h3>
          <p>Session timeout. Login again</p>
        </div>
      </Modal>

      {/* ADD NFT MODAL */}
      <Modal
        visible={state.showNftAddModal}
        onSubmit={_addNftdetails}
        title="Add NFT"
        onClose={() => state.setShowNftAddModal(false)}
        onSubmitTitle="Add"
        loading={loading}
      >
        <div className="p-6 block  md:min-w-[600px]">
          <p className="text-lg mx-4">NFT ID</p>
          <input
            placeholder={123}
            className="app-shadow p-3 m-4 w-[95%] rounded-lg focus:outline-none focus:ring focus:ring-slate-300 font-bold text-2xl dark:bg-[#333]"
            onChange={(e) => {
              setError({ ...error, add_nft: null });
              setNftId(
                e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*?)\..*/g, "$1")
              );
            }}
            // value={vals?.shareAmount ?? ''}
            type="text"
          />
          {error?.add_nft && (
            <p className="bg-[#f5691820] text-[#f56918] p-4 rounded-xl mx-4">
              {error.add_nft}
            </p>
          )}
          <p className="text-base m-4">
            Acquired an NFT from outside our platform? No worries! To register
            the transfer, simply enter the NFT ID you received from the external
            transaction. This will officially record the ownership within our
            system.
          </p>
        </div>
      </Modal>

      {/* ADD ADDRESS MODAL */}
      <Modal
        visible={add_new_address?.show ?? false}
        // onSubmit={() => null}
        title="Bind your address"
        onClose={() => {
          set_add_new_address({ ...add_new_address, show: false });
          disconnect?.();
        }}
        onSubmitTitle="Bind Address"
        loading={loading}
      >
        <div className="p-6 block md:min-w-[600px]">
          <p className="text-lg mx-4">Address</p>
          <input
            placeholder={"0xx...xx"}
            className="app-shadow p-3 m-4 w-[95%] rounded-lg focus:outline-none focus:ring focus:ring-slate-300 font-bold text-xl text-green-600 bg-transparent"
            onChange={(e) => {
              set_add_new_address({
                ...add_new_address,
                address: e.target.value,
              });
            }}
            value={add_new_address?.address ?? ""}
            type="text"
            disabled={true}
          />
          {add_new_address?.error && (
            <p className="bg-[#f5691820] text-[#f56918] p-4 rounded-xl mx-4">
              {add_new_address.error}
            </p>
          )}

          <p className="text-base m-4">
            Exercise <b>caution</b> and verify the <b>accuracy</b> of your
            wallet address when connecting it.{" "}
            <b>
              Binding the wrong address can lead to irreversible consequences
            </b>
            , including the{" "}
            <b>
              loss of valuable digital assets, staking rewards, and associated
              commissions.
            </b>{" "}
            For any doubts or assistance, reach out to our support team.
            Prioritize accuracy to safeguard your assets and enjoy a secure
            experience on our platform.
          </p>
        </div>

        <button
          className="buttonPrimary ml-[53%] w-[40%] mx-10 p-4 rounded-full border-slate-800  font-bold text-2xl text-white"
          onClick={_addNewAddress}
        >
          {loading ? "Binding..." : "Bind address"}
        </button>
      </Modal>

      {/* N O T I C E   P O L Y G O N */}
      <Modal
        onSubmit={() => {
          if (acceptT_C) {
            localStorage.setItem("@POLYGON_MODAL", true);
            setAcceptT_C(false);
            state.setShowPolygonModal(true);
          } else {
            setAcceptT_C(false);
            state.setShowPolygonModal(true);
          }
        }}
        // title="Why do we use it?"
        onSubmitTitle="OK"
        visible={!state.polygonModal}
        onClose={() => {
          state.setShowPolygonModal(true);
          setAcceptT_C(false);
        }}
        fromColor={"from-[#7c3ce3]"}
        // buttonType='warn'
        // hideOnMobile={true}
        requiredAccount={true}
      >
        <div className="px-6 md:px-20 block">
          <div className="w-[70%] absolute left-[15%] top-20">
            <img
              className="m-auto md:scale-[2.5]"
              src="https://wallet.polygon.technology/assets/img/polygon.svg"
            />
          </div>
          <p className="text-[15px] leading-5 text-justify mt-20">
            Please be aware that when using cryptocurrency on the Carbon War
            platform, it is essential to exercise caution and ensure that you
            are sending funds on the correct blockchain. Carbon War EXCLUSIVELY
            ACCEPTS cryptocurrency transactions on the POLYGON blockchain. We
            hereby inform all users that sending cryptocurrency using an
            incompatible blockchain, such as Ethereum or any other network, may
            result in permanent loss of your funds. Carbon War cannot be held
            liable or responsible for any losses incurred due to the use of an
            incorrect blockchain. To prevent any potential errors or loss of
            funds, please verify the compatibility of the blockchain network and
            address before initiating any cryptocurrency transactions on our
            platform. If you have any questions or require assistance, please
            reach out to our support team at support@carbonwar.io. Your
            adherence to this WARNING is critical to protecting your own funds
            and your understanding is greatly appreciated. Please keep your
            funds safe.
          </p>

          <span className="material-symbols-outlined text-3xl scale-[8] absolute left-[25%] opacity-30  text-[#ffb330] bottom-60 -z-8">
            warning
          </span>
          <div className="flex justify-between mt-8 items-center">
            <p className="text-base mt-4 leading-5">
              I accept full responsibility for remembering to only send accepted
              payments via the Polygon blockchain.
            </p>
            <Switch
              enabled={acceptT_C}
              onClick={() => {
                setAcceptT_C(!acceptT_C);
              }}
              enabledTitle="Accepted"
              disabledTitle="⠀Accept⠀"
              class="flex-1 mt-8"
            />
          </div>
        </div>
      </Modal>

      {/* TOAST MESSAGES */}
      {state?.message && <Prompt />}

      {state?.showApproval == true && (
        <div className="bg-[#e9c73230] dark:text-white text-dark px-[15%]  p-2 flex-none md:flex items-center justify-between">
          <div className="flex items-center">
            <span className="material-symbols-outlined text-[#e9c732] text-4xl h-10 mr-6">
              warning
            </span>
            {isAlreadyApproved && isAlreadyApprovedFNFT ? (
              <p>
                To use and interact with Carbon War’s platform, you have granted
                access to your Carbon War <b>Nft's</b> and <b>F-Nft's</b>
              </p>
            ) : (
              <p>
                To use and interact with Carbon War’s platform, you have granted
                access to your Carbon War
                <b>
                  {isAlreadyApproved
                    ? " Nft's"
                    : isAlreadyApprovedFNFT
                    ? " F-Nft's"
                    : ""}
                </b>
              </p>
            )}
          </div>
          <div className="flex items-center">
            <button
              className="bg-[#e9c732] text-black px-6 ml-4 font-bold"
              // onClick={() => nav('/stake?action=declineNFT')}
              onClick={() => {
                setLoading(true);
                if (isAlreadyApproved) {
                  removeApprovelNFT?.();
                  return;
                }
                if (isAlreadyApprovedFNFT) {
                  removeApprovelFNFT?.();
                  return;
                }
              }}
            >
              {!loading ? (
                <p className="p-2">Disapprove</p>
              ) : (
                <span className="material-symbols-outlined px-10 p-2 animate-spin">
                  refresh
                </span>
              )}
            </button>
            <button
              className="bg-[#ffffff90] text-black p-2 px-6 ml-4 font-bold"
              onClick={() => {
                localStorage.setItem("@SHOW_APPROVAL_BAR", "DO_NOT_SHOW_AGAIN");
                state?.setShowApproval("DO_NOT_SHOW_AGAIN");
              }}
            >
              OK
            </button>
          </div>
        </div>
      )}

      {/* GOTO DASHBOARD BUTTON */}
      {state.gotoDashboard &&
        state.user &&
        !props?.hideDashboardButton &&
        !state.proccessing && (
          <button className="border-2 border-slate-300 hover:bg-slate-300 fixed bottom-10 right-10 dark:border-slate-700 dark:text-slate-300 dark:md:hover:text-slate-700 ">
            <a href={USERSIDEURL} className="p-4 flex">
              <p>GO TO DASHBOARD</p>
              <span className="material-symbols-outlined ml-2">grid_view</span>
            </a>
          </button>
        )}
    </div>
  );
};

var styles = {
  input: `p-4 focus:outline-none focus:ring focus:ring-slate-300 w-full app-shadow rounded-lg dark:bg-[#333]`,
};

export default Header;
