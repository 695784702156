import React from "react";
import useStore from "../../store/AppContext";
export default function NFTCard(props) {
  const { user } = useStore();
  if (!props?.baseNfts) return;
  // var thumb_name = props?.name.replace(" ", "-").toLowerCase();
  const thumb_path =
    props.isFnft === true
      ? `/f_thumbs/${props?.name.replace(" ", "-").toLowerCase()}-f.png`
      : `/thumbs/${props?.name.replace(" ", "-").toLowerCase()}.png`;

  return (
    <div className="mb-10 hover-trigger relative">
      <div className="rounded-[20px] mx-4 w-[200px] overflow-hidden">
        <img
          className="hover:scale-105 transition scale-110"
          src={thumb_path}
          // src="/nft_alt.png"
          // src={props?.baseNfts[props?.base_nft_id - 1].url}
          onClick={props?.onCardClick}
        />
      </div>
      <div className="mt-8">
        <h2 className="text-center font-medium uppercase dark:text-[#c9c9c9]">
          <span className="text-slate-500">#{props?.nft_id}</span>{" "}
          {props?.isFnft === true ? (
            <span className="text-[#0E7FB8]">{props?.name} F-Token</span>
          ) : (
            props?.name
          )}
        </h2>
        {props?.isFnft && (
          <p className="text-center text-xs  text-slate-500">
            {
              props?.owners?.find(
                (owner) => owner.wallet_address === user?.address
              )?.balance
            }{" "}
            / {props.amount}
          </p>
        )}
      </div>
      {props?.is_staked && (
        <div className="absolute top-5 right-0 rounded-l-full px-4 pr-8 py-1 bg-gradient-to-r from-[#29d36a] from-0% via-[#29d36a] via-95% to-transparent to-100%">
          <span className="text-[#fff]">Staked {props?.staked_amount}</span>
        </div>
      )}
    </div>
  );
}
