import React from 'react'
import Dropdown from './DropDown'

export default function Filter(props) {
  return (
    <div className="flex justify-between items-center">
      <Dropdown
        className="md:w-[6%]"
        placeholder="10"
        data={new Array(10)
          .fill()
          .map((_, k) => ({ title: (k + 1) * 5 + '', value: (k + 1) * 5 }))}
        onChage={props?.onChageLimit}
        error={'no'}
      />
      {props?.title && (
        <p className="hidden md:block text-slate-400 text-2xl">{props?.title}</p>
      )}
      <div className="flex">
        <button
          onClick={() =>
            props?.activeIndex > 0 &&
            props?.goToPrevious(props?.activeIndex - 1)
          }
          className="bg-white dark:bg-[#2e2e2e] dark:text-[#c9c9c9] rounded-md shadow-lg p-[10px] mr-2"
        >
          <span className="material-symbols-outlined">chevron_left</span>
        </button>
        <button
          onClick={() => props?.goToFirst(1)}
          className="bg-white dark:bg-[#2e2e2e] dark:text-[#c9c9c9] rounded-md  px-4 p-[8px] text-slate-600"
        >
          1...
        </button>
        <button className="bg-white dark:bg-[#2e2e2e] dark:text-[#c9c9c9] p-[8px]">
          {props?.activeIndex + 1}
        </button>
        <button
          onClick={() => props?.goToLast(props?.length)}
          className="bg-white dark:bg-[#2e2e2e] dark:text-[#c9c9c9] rounded-md  px-4 p-[8px] text-slate-600"
        >
          ...{props?.length}
        </button>
        <button
          onClick={() =>
            props?.activeIndex < props?.length - 1 &&
            props?.goToNext(props?.activeIndex + 1)
          }
          className="bg-white dark:bg-[#2e2e2e] dark:text-[#c9c9c9] rounded-md shadow-lg p-[10px] ml-2"
        >
          <span className="material-symbols-outlined">chevron_right</span>
        </button>
      </div>
    </div>
  )
}
