import React from "react";
import { Outlet } from "react-router-dom";
import Nfts from "./nfts";
import { Header, Modal } from "../components";
import useStore from "../store/AppContext";
import { MintNFT } from "./nfts";
import NftFlipCard from "./components/NftFlipCard";

export default function Root() {
  const { baseNfts } = useStore();
  // console.log('baseNfts---->', baseNfts) //bg-light_bg
  return (
    <>
      <Outlet />
      <div
        id="sidebar"
        className="bg-light_bg dark:bg-dark_bg bg-no-repeat bg-cover pb-40 min-h-[100vh]"
      >
        <Header />
        {/* <div className="flex flex-wrap justify-center px-0 sm:px-40 py-4">
          {baseNfts && baseNfts.map((nft, key) => <MintNFT {...nft} />)}
        </div> */}
        <div className="flex flex-wrap justify-center px-0 py-4 mx-[10%] ">
          {baseNfts
            ? baseNfts.map((nft, key) => (
                <MintNFT key={"mintNft_" + key} {...nft} />
              ))
            : new Array(9).fill("*").map((nft_, key) => (
                <div key={'alt_img_'+key}
                  className="bg-gradient-to-tl from-slate-300 via-slate-200
                to-slate-50 w-[200px] h-[270px] rounded-[30px] bg-slate-100 mt-14 mx-6 mb-16"
                >
                  <div
                    className="app-logo-mask bg-gradient-to-r from-slate-400 via-slate-200 to-slate-300
                    background-animate w-[300px] h-[270px] -translate-x-[130px]"
                  ></div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
}
