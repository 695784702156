import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import './styles/main.css';
import "./index.css";

import Root from "./routes/root";
import ErrorPage from "./error-page";
import Nfts from "./routes/nfts";
import Stake from "./routes/stake";
import FractionalizeNFT from "./routes/fractionalizeNFT";
import Profile from "./routes/profile";
import NftView from "./routes/nftView";
import Auth from "./routes/auth";
import Admin from "./routes/admin";
import NotFound from "./routes/notfound";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, Web3Button } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import {
  arbitrum,
  mainnet,
  polygon,
  goerli,
  polygonMumbai,
} from "wagmi/chains";
import { WALLET_CONNECT_PROJECT_ID, HOSTING } from "./config";
import { AppProvider } from "./store/AppContext";

// const chains = [arbitrum, mainnet, polygon]
const chains = [HOSTING == "PRODUCTION" ? polygon : polygonMumbai];
const projectId = WALLET_CONNECT_PROJECT_ID;
const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({
    projectId,
    version: 1,
    chains,
  }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    // children: [
    //   {
    //     path: 'contacts/:contactId',
    //     element: <Contact />,
    //   },
    // ],
  },
  { path: "/nfts", element: <Nfts /> },
  { path: "/viewnft/:id", element: <NftView /> },
  { path: "/stake", element: <Stake /> },
  { path: "/fractionalize", element: <FractionalizeNFT /> },
  { path: "/profile", element: <Profile /> },
  { path: "/login/:loginString", element: <Auth /> },
  { path: "/securecwaradmin", element: <Admin /> },
  { path: "/*", element: <NotFound /> },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AppProvider>
      <WagmiConfig client={wagmiClient}>
        {/* <Web3Button /> */}
        <RouterProvider router={router} />
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        explorerExcludedWalletIds={"ALL"}
        explorerRecommendedWalletIds={[
          "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
          "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
          "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
          "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927",
        ]}
        themeVariables={{
          '--w3m-font-family': 'Roboto, sans-serif',
          '--w3m-accent-color': '#348c2c',
          '--w3m-background-color': '#fff',	
          '--w3m-logo-image-url': 'https://nft.carbonwar.io/plain_logo.png',
          // '--w3m-background-image-url': 'https://nft.carbonwar.io/plain_logo.png',
        }}
      />
    </AppProvider>
  </React.StrictMode>
);
