export const getErrorFromContract = (error) => {
  if (error && typeof error == 'object') {
    if (!error?.message) return
    const error_ = error.message?.split?.('error=')?.[1]
    const final = error_ && error_.split?.('}')[0] + '}}'
    // console.log('----->', JSON.parse(final).data.message)
    // final && setErrorMessage(JSON.parse(final).data.message)
    if (final) return JSON.parse(final).data.message
  }
  return null
}
