import React from 'react'

// const Tab1 = () => {
//   return <div>Tab one</div>
// }
// const Tab2 = () => {
//   return <div>Tab Two</div>
// }
// const Tab3 = () => {
//   return <div>Tab Three</div>
// }

export default function Tabs(props) {
  //   const tabsComps = [Tab1, Tab2, Tab3]

  return (
    <div>
      <div className="flex">
        {/* Tabs */}
        {props?.tabs.map((tab, tabIndex) => {
          return (
            <button
              key={'tab_'+tabIndex}
              onClick={() => props?.setActiveTab(tabIndex)}
              className={`text-lg tracking-wider sm:mx-4 p-4 ${
                tabIndex == props?.activeTab
                  ? 'text-slate-900 dark:text-[#c9c9c9]'
                  : 'text-slate-400 dark:text-[#666]'
              }`}
            >
              {tab}
            </button>
          )
        })}
       
      </div>
      {/* Components */}
      {/* <div>
        {tabsComps.map(
          (tab, tabIndex) => tabIndex == activeTab && tabsComps[tabIndex]?.(),
        )}
      </div> */}
    </div>
  )
}

Tabs.defaultProps = {
  tabs: ['My NFTs', 'My Stakes'],
}
