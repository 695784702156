import React, { useEffect } from "react";
import useStore from "../store/AppContext";

export default function Prompt() {
  const state = useStore();
  useEffect(() => {
    setTimeout(() => {
      state.setMessege();
    }, 10000);
  }, []);

  var bg_color =
    state.message?.type == "error"
      ? "to-red-300"
      : state.message?.type == "success"
      ? "to-green-300"
      : "to-blue-300";

  return (
    <div
      className={`bottom-0 p-8 bg-gradient-to-bl from-white ${bg_color} m-12 min-w-[500px] rounded-3xl app-shadow z-50 fixed`}
    >
      <div className="flex justify-between">
        <p
          className={`font-bold ${
            state.message?.type == "error"
              ? "text-red-600"
              : state.message?.type == "success"
              ? "text-green-600"
              : "text-blue-600"
          } text-3xl`}
        >
          {state.message?.title}
        </p>
        <button
          className="bg-black text-white w-12 h-12 rounded-full"
          onClick={() => state.setMessege()}
        >
          <span className="material-symbols-outlined mt-1 hover:font-bold">
            close
          </span>
        </button>
      </div>
      <div className="">
        {state.message?.render?.()}
        {/* <p>{state.message}</p> */}
      </div>
    </div>
  );
}
