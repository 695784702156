import React from 'react'

{
  /* <Switch
enabled={accept}
onClick={() => null}
enabledTitle="accept"
disabledTitle="denay"
class=""
/> */
}

export default function Switch(props) {
  const enabled = props?.enabled
  return (
    <div>
      <button
        className={`${
          enabled ? 'buttonPrimary' : 'bg-white'
        } rounded-full text-white flex items-center p-1 ${props?.class ?? ''}`}
        onClick={props?.onClick}
      >
        {!enabled && (
          <div className="bg-slate-500 w-[30px] h-[30px] rounded-full self-start"></div>
        )}
        <p className={`px-4 ${enabled ? 'text-white' : 'text-slate-400'}`}>
          {enabled
            ? props?.enabledTitle ?? 'Enabled'
            : props?.disabledTitle ?? 'Disabled'}
        </p>
        {enabled && (
          <div className="bg-white w-[30px] h-[30px] rounded-full self-start"></div>
        )}
      </button>
    </div>
  )
}
