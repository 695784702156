import axios from "../axios";
import { createContext, useReducer, useContext } from "react";
import shopReducer, { initialState } from "./AppReducer";

const AppContext = createContext(initialState);

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shopReducer, initialState);

  // const addToCart = (product) => {
  //   const updatedCart = state.products.concat(product)
  //   updatePrice(updatedCart)
  //   dispatch({
  //     type: 'ADD_TO_CART',
  //     payload: {
  //       products: updatedCart,
  //     },
  //   })
  // }
  // const removeFromCart = (product) => {
  //   const updatedCart = state.products.filter(
  //     (currentProduct) => currentProduct.name !== product.name,
  //   )
  //   updatePrice(updatedCart)

  //   dispatch({
  //     type: 'REMOVE_FROM_CART',
  //     payload: {
  //       products: updatedCart,
  //     },
  //   })
  // }
  // const updatePrice = (products) => {
  //   let total = 0
  //   products.forEach((product) => (total += product.price))

  //   dispatch({
  //     type: 'UPDATE_PRICE',
  //     payload: {
  //       total,
  //     },
  //   })
  // }

  const signin = (user) => {
    dispatch({
      type: "SIGNIN",
      payload: user,
    });
  };

  const setShowRegModal = (bool) => {
    dispatch({
      type: "SHOWREGMODAL",
      payload: bool,
    });
  };
  const setShowSignModal = (bool) => {
    dispatch({
      type: "SHOWSIGNMODAL",
      payload: bool,
    });
  };

  const setShowNftAddModal = (bool) => {
    dispatch({
      type: "SHOWNFTADDMODAL",
      payload: bool,
    });
  };

  const setMessege = (msg) => {
    dispatch({
      type: "SETMESSAGE",
      payload: msg,
    });
  };

  const saveToLocalStorage = (key = "@user", state) => {
    try {
      const serialisedState = JSON.stringify(state);
      localStorage.setItem(key, serialisedState);
      // console.log('SAVING LOCALSTORAGE', key, state)
    } catch (e) {
      console.warn(e);
    }
  };

  const getBaseNfts = () => {
    axios
      .get("common/getBaseNfts")
      .then((res) => {
        // console.log(res.data)
        dispatch({
          type: "SETBASENFTS",
          payload: res.data.data.sort((a, b) => (a.price > b.price ? 1 : -1)),
        });
      })
      .catch((error) => {
        // console.log('SETBASENFTS error:', error)
      });
  };

  const getBasePlans = () => {
    axios
      .get("common/getStakeplans")
      .then((res) => {
        // console.log("getStakeplans----", res.data)
        dispatch({
          type: "SETBASEPLANS",
          payload: res.data.data,
        });
      })
      .catch((error) => {
        // console.log('SETBASEPLANS error:', error)
      });
  };

  const setBaseCurrencyValue = (val) => {
    dispatch({
      type: "SETBASCURRVAL",
      payload: val,
    });
  };

  const setShowApproval = (val) => {
    dispatch({
      type: "SETDISAPPROVALBANNER",
      payload: val,
    });
  };

  const setProccess = (val) => {
    dispatch({
      type: "SETPROCCESS",
      payload: val,
    });
  };
  const setGotoDashboard = (val) => {
    dispatch({
      type: "SETGOTODASHBOARD",
      payload: val,
    });
  };

  const setShowPolygonModal = (val) => {
    dispatch({
      type: "POLYGON_MODAL",
      payload: val,
    });
  };

  const value = {
    ...state,
    signin,
    setShowRegModal,
    setShowSignModal,
    saveToLocalStorage,
    getBaseNfts,
    getBasePlans,
    setMessege,
    setShowNftAddModal,
    setBaseCurrencyValue,
    setShowApproval,
    setProccess,
    setGotoDashboard,
    setShowPolygonModal,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useStore = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useStore must be used within StoreContext");
  }

  return context;
};

export default useStore;
