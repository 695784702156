import React from 'react'
import '../style.css'
import useStore from '../../store/AppContext'

export default function StakingDetails(props) {
  const { baseNfts, basePlans } = useStore()
  return (
    // md:min-w-[32%] md:max-w-[50%] 
    <div className="backdrop-blur-md sm:ml-10 bg-[#ffffff90] rounded-[25px] p-4 tracking-wide app-shadow flex-2 md:min-w-[60%] md:max-w-[50%] overflow-x-scroll scrollbar-hide  dark:bg-[#0d0d0d50] dark:text-[#c9c9c9]">
      <h3 className="p-4 text-2xl font-bold text-center text-slate-700 dark:text-[#c9c9c9]">Staking Rewards Table</h3>

      <table className="w-full">
        <thead>
          <tr className="w-full">
            {/* <th className="font-normal text-slate-700 text-left">NFT</th> */}
            <th></th>
            {basePlans &&
              basePlans.map((plan, key) => {
                return (
                  <th
                    key={'_stake_table_title' + key}
                    className={`font-bold  ${plan.plan_id == props.planId
                      ? 'text-green-600'
                      : 'text-slate-700 dark:text-[#c9c9c9]'
                      }`}
                  >
                    {plan.title}
                  </th>
                )
              })}
          </tr>
        </thead>
        <tbody>
          {baseNfts &&
            baseNfts.map((nft, key) => {
              var hilightColor = `text-green-600 font-bold border-green-600 border-b-4 bg-green-100 dark:bg-[#ffffff10]`
              return (
                  <tr
                    key={'_stake_table' + key}
                    className={`w-full ${nft.id == props.nft
                      ? 'bg-green-50 border-2 border-green-200 dark:border-0 dark:bg-[#ffffff10]'
                      : ''
                      } `}
                  >
                    <th className="text-left font-bold">{nft.name}</th>
                    <td
                      className={`text-left ${props?.planId == 1 && props?.nft == nft.id
                        ? hilightColor
                        : 'text-slate-600 dark:text-[#c9c9c9]'
                        }`}
                    >
                      {nft.rewards[1] / 250}{' '}
                      <span className="text-[9px]">CC</span>
                    </td>
                    <td
                      className={`text-left ${props?.planId == 2 && props?.nft == nft.id
                        ? hilightColor
                        : 'text-slate-600 dark:text-[#c9c9c9]'
                        }`}
                    >
                      {nft.rewards[2] / 250}{' '}
                      <span className="text-[9px]">CC</span>
                    </td>
                    <td
                      className={`text-left ${props?.planId == 3 && props?.nft == nft.id
                        ? hilightColor
                        : 'text-slate-600 dark:text-[#c9c9c9]'
                        }`}
                    >
                      {nft.rewards[3] / 250}{' '}
                      <span className="text-[9px]">CC</span>
                    </td>
                    <td
                      className={`text-left ${props?.planId == 4 && props?.nft == nft.id
                        ? hilightColor
                        : 'text-slate-600 dark:text-[#c9c9c9]'
                        }`}
                    >
                      {nft.rewards[4] / 250}{' '}
                      <span className="text-[9px]">CC</span>
                    </td>
                  </tr>
              )
            })}
        </tbody>
      </table>
      <p className="text-xs text-center px-12 my-4 text-slate-700 dark:text-slate-400">
        NOTE : In this table, “cc” represents the corresponding number of tokenized Carbon Certificates that will be earned ANNUALLY
        from Staking.  For example, if you select “3 year staking” then you will earn the corresponding tokenized Carbon Certificates
        each year for 3 years.
      </p>
    </div>
  )
}
