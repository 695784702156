var WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
var AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
var BACKOFFICE_API_KEY = process.env.REACT_APP_BACKOFFICE_API_KEY;
var SESSION_TIME = process.env.REACT_APP_SESSION_TIME;
var SERVER_HOST = "";
var BACKOFFICE_API = "";
var USERSIDEURL = "";
var ADMIN_ADDRESS = "";
var USDT_ADDRESS = "";
var USDC_ADDRESS = "";
var NFT_ADDRESS = "";
var UTILS_ADDRESS = "";
var FNFT_ADDRESS = "";
var CWAR_ADDRESS = "";
var HOSTING = process.env.REACT_APP_HOSTING;

if (HOSTING === "PRODUCTION") {
    // ................................
    //            L I V E
    // ................................

    SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
    BACKOFFICE_API = process.env.REACT_APP_BACKOFFICE_API;
    USERSIDEURL = process.env.REACT_APP_USERSIDEURL;
    // .....  C O N T R A C T  A D D R E S S  .....
    ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;
    USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS;
    USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS;
    NFT_ADDRESS = process.env.REACT_APP_NFT_ADDRESS;
    UTILS_ADDRESS = process.env.REACT_APP_UTILS_ADDRESS;
    FNFT_ADDRESS = process.env.REACT_APP_FNFT_ADDRESS;
    CWAR_ADDRESS = process.env.REACT_APP_CWAR_ADDRESS;

} else {
    // ................................
    //      D E V E L O P M E N T
    // ................................

    SERVER_HOST = process.env.REACT_APP_SERVER_HOST_TEST;
    BACKOFFICE_API = process.env.REACT_APP_BACKOFFICE_API_TEST;
    USERSIDEURL = process.env.REACT_APP_USERSIDEURL_TEST;
    // .....  C O N T R A C T  A D D R E S S  .....
    ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS_TEST;
    USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS_TEST;
    USDC_ADDRESS = process.env.REACT_APP_USDC_ADDRESS_TEST;
    NFT_ADDRESS = process.env.REACT_APP_NFT_ADDRESS_TEST;
    UTILS_ADDRESS = process.env.REACT_APP_UTILS_ADDRESS_TEST;
    FNFT_ADDRESS = process.env.REACT_APP_FNFT_ADDRESS_TEST;
    CWAR_ADDRESS = process.env.REACT_APP_CWAR_ADDRESS_TEST;
}

export {
    WALLET_CONNECT_PROJECT_ID,
    SERVER_HOST,
    AUTH_TOKEN,
    BACKOFFICE_API,
    BACKOFFICE_API_KEY,
    USERSIDEURL,
    ADMIN_ADDRESS,
    USDT_ADDRESS,
    USDC_ADDRESS,
    NFT_ADDRESS,
    UTILS_ADDRESS,
    FNFT_ADDRESS,
    CWAR_ADDRESS,
    SESSION_TIME,
    HOSTING
};
