import React, { useCallback, useEffect, useState } from "react";
import { getProvider } from "@wagmi/core";
import {
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useAccount,
} from "wagmi";
import { useWeb3Modal, Web3Button } from "@web3modal/react";
import axios from "../axios";
import Web3 from "web3";
// import { ethers } from 'ethers'

// import nftABI from '../abi/TestCarbonWarNFT.json' // 0xcAFe03675AF4cDFB936F10ACb91dAD987D91E029 - nft demo polygon
// import TokenABI from '../abi/TestToken.json' // 0x6eaf4cd2559442b1fc8ad467977b7a678ee51913
import USDT from "../abi/USDT.json"; // 0x7b43466289198eEfFB422b9d5d03bBB9A53ED045
import USDC from "../abi/USDC.json"; // 0xDA5b73146ef49b64Aa272616AE1AD3dF9df54e23
import useStore from "../store/AppContext";
import { ADMIN_ADDRESS, USDT_ADDRESS, USDC_ADDRESS } from "../config";
import { Header, Modal } from "../components";
import { useNavigate } from "react-router-dom";
// import NFTCard from './components/NFTCard'
import NFTCard from "./components/NftFlipCard";

export function MintNFT(props) {
  const [progress, setProgress] = useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState(false);
  const [requiredBalance, setRequiredBalance] = useState();
  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    user,
    setShowRegModal,
    setShowSignModal,
    setMessege,
    baseCurrencyValue,
    setProccess: setProccessOnStore,
    proccessing,
  } = useStore();
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();

  const {
    config: configTransferToken,
    error: transferError,
    refetch,
    status,
  } = usePrepareContractWrite({
    enabled: requiredBalance && props?.price ? true : false,
    address: user?.default_payment == "USDT" ? USDT_ADDRESS : USDC_ADDRESS,
    abi: user?.default_payment == "USDT" ? USDT : USDC,
    functionName: "transfer",
    args: [
      ADMIN_ADDRESS,
      Web3.utils.toWei(
        (baseCurrencyValue == 0 ? props.price : props.price / baseCurrencyValue)
          .toFixed(6)
          .toString(),
        "mwei"
      ),
    ],
    // args: [ADMIN_ADDRESS, props.price],
    // overrides: {
    //   from: address,
    //   // value: ethers.utils.parseEther('0.01'),
    // },
    // listener(node, label, owner) {
    //   console.log(node, label, owner)
    // },
  });

  // Mint Token
  // const { config } = usePrepareContractWrite({
  //   enabled: false,
  //   address: '0x33FbD803b8337F55f78669E89B77A15122929cfC',
  //   abi: nftABI,
  //   functionName: "mint",
  // })
  // const { data, isLoading, isSuccess, write: mint } = useContractWrite({
  //   ...config,
  //   onSuccess(data) {
  //     console.log('MINTED NFT', data)
  //   },
  // })
  const {
    data,
    isLoading,
    isSuccess: transferSuccess,
    write: transfer,
  } = useContractWrite({
    ...configTransferToken,
    onSuccess: async (data) => {
      // console.log('trying to mint nft...')
      // mint?.()
      //usdt transfer
      const trx = {
        contract: user.default_payment == "USDT" ? USDT_ADDRESS : USDC_ADDRESS,
        type: "transfer",
        transactionHash: data.hash,
        name: user.default_payment == "USDT" ? "USDT" : "USDC",
        from: address,
        to: ADMIN_ADDRESS,
        amount: (props.price / baseCurrencyValue).toFixed(6),
        base_nft_id: props?.id,
        sponsor_id: user?.sponsor_id,
        user_id: user?.user_id,
        wallet_address: address,
        timestamp: Math.floor(Date.now() / 1000),
        status: "pending",
      };
      // console.log('trx ////// ', trx)
      await axios
        .post("user/add_transaction", trx)
        .then((res) => {
          // console.log(res.data)
        })
        .catch((error) => {
          // console.log('error:', error)
        });

      // console.log('Await for blockchain confirmation...')
      await data.wait();

      axios
        .post(
          "user/mint",
          {
            transactionHash: data.hash,
            to: address,
            base_nft_id: props?.id,
            nft_series: props?.current_series,
          },
          { withCredentials: true }
        )
        .then((res) => {
          console.log("MINTING", res.data);
          if (res.data.status) {
            // console.log('MINTING SUCCESS')
            // TOAST MESSAGE
            setMessege({
              title: `Minting success`,
              render: () => (
                <p>{`NFT #${res.data?.data?.data?.tokenId} successfully minted.`}</p>
              ),
              type: "success",
            });
            // set processing false in store
            setProccessOnStore(false);
            setProgress(false);
          } else {
            setMessege({
              title: `NFT minting failed!`,
              render: () => <p>{res.data?.message}</p>,
              type: "error",
            });
            setProgress(false);
            // console.log('MINTING ERROR')
          }
        })
        .catch((error) => {
          // console.log('error:', error)
        });
    },
    onError(error) {
      // console.log('Error', error)
      setRequiredBalance();
      setProgress(false);
      setshowConfirmModal();
      setProccessOnStore(false);
    },
  });
  const navigate = useNavigate();

  var thumb_name = props?.name?.replace(" ", "-").toLowerCase();
  return (
    <>
      <NFTCard
        {...props}
        onCardClick={() =>
          proccessing ? null : navigate(`/viewnft/${props.id}`)
        }
        onPayClick={async () => {
          try {
            // console.log(
            //   'ON ONPAYKLICK USER PAYMENT CURR',
            //   user?.default_payment,
            // )
            // console.log('ON requiredBalance', requiredBalance ? true : false)
            if (transferSuccess) {
              navigate(`/profile`);
              // console.log('ON SUCCESS TRANFER')
              return;
            }

            // progress &&  console.log('ON PREVENT PROGRESS')
            if (progress) return; // prvent double click
            setProgress(true);

            if (user) {
              // CHECK BALANCE OF
              const {
                data: {
                  data: { balance },
                },
              } = await axios.get(`user/mybalances/${address}`);

              if (balance[user?.default_payment] <= props.price) {
                // console.log('ON NO BALANCE')
                // alert('No USDT Balance')
                setMessege({
                  title: `Not enough ${user.default_payment} balance`,
                  render: () => (
                    <p>
                      {user.default_payment} balance is{" "}
                      {balance[user?.default_payment]}
                    </p>
                  ),
                  type: "error",
                });
                setProgress(false);
                setRequiredBalance();
                return;
              } else {
                // console.log('ON TRANSFER')
                setRequiredBalance(balance);
                // transfer?.()
                setshowConfirmModal(true);
              }
            } else {
              // console.log('USER- >', user)
              // console.log('ON NO USER')

              setProgress(false);
              if (address) {
                setShowRegModal(true);
                // console.log('ON SHOW REG MODAL')
              } else {
                open();
                // console.log('ON WALLET CONNECT')
              }
              // var { data } = await axios.get(`/user/getUser/${address}`)
              // console.log('hasUser->', data.data)
              // if (data?.data) {
              //   setShowSignModal(true)
              // } else {
              //   setShowRegModal(true)
              // }
            }
            // user
            //   ? transfer?.()
            //   : address
            //   ? setShowSignModal(true)
            //   : setShowRegModal(true)
          } catch (error) {
            // console.log('UNEXPECTED ERROR', error)
            setProgress(false);
            window.alert("Try again!");
          }
        }}
        progress={isLoading || progress}
        transferSuccess={transferSuccess && !proccessing}
        mintingError={transferSuccess && !progress && proccessing}
      />
      {/* BUY NFT MODAL */}
      <Modal
        visible={showConfirmModal}
        onSubmit={() => {
          transfer?.();
          setshowConfirmModal();
          setProccessOnStore(true);
        }}
        title={`Buy NFT - ${props?.name}`}
        onClose={() => {
          setshowConfirmModal();
          setProgress();
        }}
        onSubmitTitle="Confirm"
        // loading={progress}
      >
        {showConfirmModal && (
          <div className="p-6 block md:min-w-[600px]">
            <div className="flex items-center">
              <div className="rounded-[30px] m-auto">
                <img
                  className="rounded-2xl"
                  src={`/thumbs/${thumb_name}.png`}
                />
              </div>
              <div className="p-2 ml-8  w-[50%]">
                <p className="text-1xl text-slate-400 mt-2 font-light">
                  CURRENCY
                </p>
                <p className="text-2xl font-bold border-b-2">
                  {user?.default_payment}
                </p>
                <p className="text-1xl text-slate-400 mt-2 font-light">PRICE</p>
                <p className="text-2xl font-bold border-b-2">${props?.price}</p>

                <p className="text-1xl text-slate-400 mt-2 font-light">
                  {user?.default_payment}
                </p>
                <div className="flex items-center">
                  <img
                    className="rounded-2xl w-5 h-5 my-2 mr-2"
                    src={`/coins/${user?.default_payment ?? "USDT"}.png`}
                  />
                  <p className="text-2xl font-bold">
                    {(
                      props?.price / baseCurrencyValue +
                      ((props?.price / baseCurrencyValue) * 0.1) / 100
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

function Nfts() {
  const { baseNfts, user } = useStore();
  // const { address, isConnecting, isDisconnected } = useAccount()
  // const { data: balance, isError, isLoading } = useContractRead({
  //   address: user.default_payment == 'USDT' ? USDT_ADDRESS : USDC_ADDRESS,
  //   abi: user.default_payment == 'USDT' ? USDT : USDC,
  //   functionName: 'balanceOf',
  //   args: [address],
  // })
  if (!user) return;
  return (
    <>
      <Header title="NFT" />
      <div className="flex flex-wrap justify-between p-20">
        {baseNfts &&
          baseNfts.map((nft, k) => {
            return (
              <MintNFT
                key={"MintNFT_" + k}
                {...nft}
                // balance={Web3.utils.fromWei(balance?.toString(), 'ether')}
              />
            );
          })}
      </div>
    </>
  );
}

export default Nfts;
