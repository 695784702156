import React, { useState } from "react";
import Button from "./Button";

export default function Modal(props) {
  const shadefromColor =
    props?.buttonType == "success"
      ? "from-[#3fee8299]"
      : props?.buttonType == "error"
      ? "from-[#eb003b99]"
      : props?.buttonType == "warn"
      ? "from-[#f5691899]"
      : props?.fromColor ?? "from-[#4497f999]";

  return (
    <>
      {props?.visible ? (
        <>
          <div
            className={`${
              props?.hideOnMobile ? "phone:hidden" : ""
            } justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[#00000040] dark:bg-[#00000090]`}
          >
            <div className={`${props?.className ?? 'phone:scale-90'}  relative w-auto my-6 mx-auto max-w-3xl`}>
              {/*content*/}
              <div
                className={`border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-[#fffffff2] dark:bg-[#000000] outline-none focus:outline-none bg-gradient-to-bl ${shadefromColor} from-0% via-[#ffffff20] dark:via-[#00000020] via-65% to-transparent to-100%  text-black dark:text-white`}
              >
                {/*header*/}
                {/* border-b border-solid border-slate-200 */}
                <div className="flex items-start justify-between p-8  rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props?.title ?? ""}
                  </h3>
                  <button
                    className="bg-white text-black dark:text-white dark:bg-black w-12 h-12 rounded-full"
                    onClick={props?.onClose}
                  >
                    <span className="material-symbols-outlined mt-1 hover:font-bold">
                      close
                    </span>
                  </button>
                </div>
                {/*body*/}

                {props?.children}
                {/*footer*/}
                {/* border-t border-solid border-slate-200 */}
                <div className="flex items-center justify-end p-6 rounded-b">
                  {props?.onSubmit && (
                    <Button
                      loading={props?.loading}
                      title={props?.onSubmitTitle ?? "Save Changes"}
                      onClick={props.onSubmit}
                      type={props?.buttonType}
                      className="px-8 py-2 font-bold"
                      disabled={props?.buttonDisabled}
                      requiredAccount={props?.requiredAccount}
                    />
                    // <button
                    //   className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    //   type="button"
                    //   onClick={props.onSubmit}
                    // >
                    //   {props?.onSubmitTitle ?? 'Save Changes'}
                    // </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${
              props?.hideOnMobile ? "phone:hidden" : ""
            } opacity-25 fixed inset-0 z-40 bg-black`}
            onClick={props?.onClose}
          ></div>
        </>
      ) : null}
    </>
  );
}
