import React, { useState } from "react";
import { Button, Modal } from "../../components";
import { useAccount } from "wagmi";
import { CWAR_ADDRESS, USDT_ADDRESS, USDC_ADDRESS } from "../../config";
import { NFT_ADDRESS } from "../../config";
import { FNFT_ADDRESS } from "../../config";

export default function AddAssets(props) {
  let element = document.querySelector("html");
  const currentTheme = element.getAttribute("class");
  
  const Copy = (props) => {
    // works if (navigator.clipboard.writeText) didn't work
    const textarea = document.createElement("textarea");
    textarea.value = props?.value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);


    const [isCopied, setIsCopied] = useState(false);
    return (
      <button
        className="ml-4"
        title="Copy"
        onClick={() => {
          props?.onClick?.();
          setIsCopied(!isCopied);
        }}
      >
        <span
          className={`material-symbols-outlined text-4xl ${
            isCopied ? "text-green-600" : ""
          }`}
        >
          {!isCopied ? "content_copy" : "done_outline"}
        </span>
      </button>
    );
  };

  var tokens = [
    {},
    {
      tokenAddress: CWAR_ADDRESS,
      tokenSymbol: "CWAR",
      tokenDecimals: 18,
      tokenImage: "",
      type: "ERC20",
    },
    {
      tokenAddress: USDT_ADDRESS,
      tokenSymbol: "USDT",
      tokenDecimals: 6,
      tokenImage: "",
      type: "ERC20",
    },
    {
      tokenAddress: USDC_ADDRESS,
      tokenSymbol: "USDC",
      tokenDecimals: 6,
      tokenImage: "",
      type: "ERC20",
    },
  ];

  async function addTokenToWallet(token) {
    // const tokenAddress = '0xda5b73146ef49b64aa272616ae1ad3df9df54e23'
    // const tokenSymbol = 'FEL'
    // const tokenDecimals = 18
    // const tokenImage = 'http://placekitten.com/200/300'
    // const type = 'ERC20'
    const { tokenAddress, tokenSymbol, tokenDecimals, tokenImage, type } =
      token;
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum?.request({
        method: "wallet_watchAsset",
        params: {
          type: type, // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        // console.log('Thanks for your interest!')
        props.setaddassets({
          ...props.addasset,
          visible: false,
        });
      } else {
        // console.log('Your loss!')
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      visible={props.addasset?.visible}
      className="scale-50"
      onSubmit={() => {
        if (props?.addasset.activeTab != 0) {
          props.setaddassets({
            ...props.addasset,
            visible: false,
          });
          return;
        }
        var { activeStep } = props.addasset;
        if (activeStep == 3) {
          props.setaddassets({
            ...props.addasset,
            visible: false,
          });
          return;
        }
        props.setaddassets({
          ...props.addasset,
          activeStep: activeStep + 1,
        });
      }}
      title="Add assets to your wallet"
      onClose={() =>
        props.setaddassets({
          ...props.addasset,
          visible: false,
        })
      }
      onSubmitTitle={
        props?.addasset.activeTab != 0
          ? "Ok"
          : props?.addasset.activeStep > 2
          ? "Done"
          : "Next"
      }
      loading={false}
    >
      <div className="w-[750px] h-[520px]">
        {/* TABS */}
        <div className="flex justify-between h-[60px] overflow-hidden">
          {["NFT/F-NFT", "CWAR", "USDT", "USDC"].map((item, key) => {
            return (
              <button
                key={"tabs" + key}
                className={`${
                  props.addasset.activeTab == key
                    ? "flex-1 bg-gradient-to-r from-[#5E9EBE60] to-[#5E9EBE] text-white"
                    : "bg-gradient-to-r from-[#5E9EBE60]"
                } hover:px-6 text-slate-500 ease-linear transition-all duration-150
                 py-4 px-10  text-lg app-shadow
                  `}
                onClick={() =>
                  props.setaddassets({ ...props.addasset, activeTab: key })
                }
              >
                <p className="text-2xl">{item}</p>
              </button>
            );
          })}
        </div>

        {/* CONTENTS */}
        {/* TAB - 1,2,3  */}
        {props?.addasset.activeTab != 0 &&
          tokens.map((token, token_key) => {
            if (props?.addasset.activeTab != token_key) return;
            return (
              <div className="px-16" key={token_key}>
                <Button
                  title={`ADD ${token.tokenSymbol} TOKEN TO WALLET`}
                  className="p-4 m-8"
                  onClick={() => {
                    addTokenToWallet(token);
                  }}
                />
                <p className="text-center border-b h-[10px]  dark:opacity-30 mt-2">
                  <b className="border rounded-full p-2 bg-white dark:bg-black">
                    or
                  </b>
                </p>

                <p className="text-2xl mt-10">
                  <b>Token contract address</b>
                </p>
                <div className="flex items-center ">
                  {token.tokenAddress}
                  <Copy
                    onClick={() =>
                      navigator.clipboard.writeText(token.tokenAddress)
                    }
                    value={token.tokenAddress}
                  />
                </div>
                <p className="text-2xl mt-10">
                  <b>Token symbol</b>
                </p>
                <div className="flex items-center ">
                  {token.tokenSymbol}
                  <Copy
                    onClick={() =>
                      navigator.clipboard.writeText(token.tokenSymbol)
                    }
                    value={token.tokenSymbol}
                  />
                </div>
                <p className="text-2xl mt-10">
                  <b>Token decimal</b>
                </p>
                <div className="flex items-center ">
                  {token.tokenDecimals}
                  <Copy
                    onClick={() =>
                      navigator.clipboard.writeText(token.tokenDecimals)
                    }
                    value={token.tokenDecimals}
                  />
                </div>
              </div>
            );
          })}
        {/* TAB - 0  */}
        {props?.addasset.activeTab == 0 && (
          <div className="p-8">
            {/* ACTIVE STEP */}
            <div className="flex justify-center my-6 mb-16">
              {[1, 2, 3, 4].map((_, active_k) => (
                <div
                  className="relative left-[-20px] cursor-pointer"
                  key={active_k}
                  onClick={() =>
                    props.setaddassets({
                      ...props.addasset,
                      activeStep: active_k,
                    })
                  }
                >
                  {active_k != 0 && (
                    <span
                      className={`absolute w-[40px] h-[1px] ${
                        active_k <= props.addasset.activeStep
                          ? "bg-[#0E7FB8]"
                          : "bg-[#bbb]"
                      } top-2`}
                    ></span>
                  )}
                  <span
                    className={`border-2 ${
                      active_k <= props.addasset.activeStep
                        ? "border-[#0E7FB8] text-[#0E7FB8] bg-[#0E7FB810]"
                        : "border-[#bbb] text-[#bbb]"
                    } p-6 rounded-full ml-[40px]`}
                  >
                    {"0" + _}
                  </span>
                </div>
              ))}
            </div>
            {props.addasset.activeStep == 0 && (
              <>
                <p className="text-base text-center my-12 text-slate-500">
                  (NOTE: Import works only for NFTs not staked)
                </p>
                <p className="text-2xl text-center">
                  <b>Copy NFT contract address</b>
                </p>
                <div className="flex items-center justify-center">
                  {NFT_ADDRESS}
                  <Copy
                    onClick={() => navigator.clipboard.writeText(NFT_ADDRESS)}
                    value={NFT_ADDRESS}
                  />
                </div>
                <p className="text-center border-b h-[10px] dark:opacity-30 mt-2">
                  <b className="border rounded-full p-2 bg-white dark:bg-black">
                    or
                  </b>
                </p>
                <p className="text-2xl mt-8 text-center">
                  <b>Copy F-NFT contract address</b>
                </p>
                <div className="flex items-center justify-center">
                  {FNFT_ADDRESS}
                  <Copy
                    onClick={() => navigator.clipboard.writeText(FNFT_ADDRESS)}
                    value={FNFT_ADDRESS}
                  />
                </div>
              </>
            )}
            {props.addasset.activeStep == 1 && (
              <>
                <p className="text-2xl mt-8 mx-8 text-center">
                  Open wallet {" > "} go to NFTs {" > "}
                  <b>Import NFTs</b>
                </p>
                <img
                  src={
                    currentTheme == "dark"
                      ? "./nft_adding_dark.png"
                      : "./nft_adding.png"
                  }
                  className="w-[50%] m-auto"
                />
              </>
            )}
            {props.addasset.activeStep == 2 && (
              <>
                <p className="text-2xl mt-8 text-center">
                  Input copied <b>Contract Address</b> and <b>NFT ID</b>
                </p>
                <img
                  src={
                    currentTheme == "dark"
                      ? "./nft_adding_2_dark.png"
                      : "./nft_adding_2.png"
                  }
                  className="w-[50%] m-auto"
                />
              </>
            )}
            {props.addasset.activeStep == 3 && (
              <>
                <p className="text-2xl mt-8 mx-8 text-center text-green-500">
                  You are done!
                </p>
                <img
                  src={
                    currentTheme == "dark"
                      ? "./nft_added_dark.png"
                      : "./nft_added.png"
                  }
                  className="w-[50%] m-auto"
                />
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
