import axios from 'axios'
import { SERVER_HOST, AUTH_TOKEN, ADMIN_AUTH_TOKEN } from './config'
//base url to make reuests to the movie database
const instance = axios.create({
  baseURL: SERVER_HOST,
  withCredentials: true,
  headers: {
    // Authorization : `Bearer ${localStorage.getItem("access_token")}`,
    'x-auth-token': AUTH_TOKEN,
  },
})
export default instance;


//base url to make reuests to the movie database
export const adminInstance = axios.create({
  baseURL: SERVER_HOST,
  withCredentials: true,
  headers: {
    // Authorization : `Bearer ${localStorage.getItem("access_token")}`,
    'x-auth-token': AUTH_TOKEN,
  },
})

