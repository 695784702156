import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Header, DropDown, Modal, Button } from "../components";
import "./style.css";
import StakingDetails from "./components/StakingDetails";
import { getErrorFromContract } from "../service/helper";

import { UTILS_ADDRESS, NFT_ADDRESS, FNFT_ADDRESS } from "../config";
import axios from "../axios";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import useStore from "../store/AppContext";
import CWARUTILS from "../abi/CWARUTILS.json";
import CWARNFT from "../abi/CWARNFT.json";
import CWARFRACTIONAL from "../abi/CWARFRACTIONAL.json";
import NFTCard from "./components/MyNFTCard";

// var array = [{name: 'amir', val: '1'}, {name: 'rob', val: '2'}, {name: 'paju', val: '3'}, {name: 'amir', val: '1'}]

// const result = Object.values(array.reduce((acc, obj) => {
//   if (!acc[obj.val]) {
//     acc[obj.val] = {...obj, count: 0};
//   }
//   acc[obj.val].count++;
//   return acc;
// }, {}));

// console.log(result);

export default function Stake() {
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [messege, setMessege] = useState({
    type: "error",
    message: null,
  });
  const [mynfts, setMynfts] = useState();
  const [myStakedNfts, setMyStakedNfts] = useState();
  const [showApprovalConfirm, setShowApprovalConfirm] = useState(false);
  const [showStakeConfirm, setShowStakeConfirm] = useState(false);
  const [progress, setProgress] = useState(false);
  const [myFnfts, setMyFnfts] = useState();
  const [loading, setLoading] = useState();
  const [vals, setVals] = useState({});
  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    baseNfts,
    user,
    basePlans,
    message: stateMessage,
    setMessege: stateSetMessege,
    setShowNftAddModal,
    showNftAddModal,
    setProccess: setProccessOnStore,
  } = useStore();
  const navigate = useNavigate();
  // const queryParameters = new URLSearchParams(window.location.search)
  // var action = queryParameters.get('action')
  // console.log('---------------->', action)
  //   STAKE
  //   console.log( nfts.filter(_=>( _.isFnft == true && _.staked_amount < _.amount)|| _.is_staked == false) )
  //   FRAC
  //   console.log( nfts.filter(_=> _.isFnft == false && _.is_staked == false))
  //   DEFRAC
  //   console.log( nfts.filter(_=> _.isFnft == true && _.is_staked == false))

  useEffect(() => {
    _getMyNfts();
  }, [address, showNftAddModal]);

  const _getMyNfts = () => {
    setLoading(true);
    axios
      .get(`/user/mynfts/${address}`)
      .then((res) => {
        if (res.data.status) {
          if (res.data.data.length == 0) {
            setMessege({ ...messege, message: "No NFT Balance!" });
          }
          // setMynfts(res.data.data.filter((_) => (_.staked == false)))
          setMynfts(
            res.data.data
              .filter(
                (_) =>
                  (_.isFnft == true && _.staked_amount < _.amount) ||
                  _.is_staked == false
              )
              .map((_) => ({
                ..._,
                name: _.isFnft ? _.name + " F-Token" : _.name,
              }))
          );
          setMyStakedNfts(res.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          setMynfts();
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log('ERROR', error)
      });
  };

  // CHECK IS ALREADY APPROVED
  const {
    data: isAlreadyApproved,
    isError,
    refetch,
    isLoadingApproval,
  } = useContractRead({
    address: NFT_ADDRESS,
    abi: CWARNFT,
    functionName: "isApprovedForAll",
    args: [address, UTILS_ADDRESS],
  });

  const [hasNftApproval, setHasNftApproval] = useState(isAlreadyApproved);

  // CHECK IS ALREADY APPROVED FNFT
  const {
    data: isAlreadyApprovedFNFT,
    isError: isErrorFNFT,
    refetch: f_refetch,
    isLoadingApproval: isLoadingApprovalFNFT,
  } = useContractRead({
    address: FNFT_ADDRESS,
    abi: CWARFRACTIONAL,
    functionName: "isApprovedForAll",
    args: [address, UTILS_ADDRESS],
  });
  // console.log("isAlreadyApprovedFNFT->",isAlreadyApprovedFNFT);
  // console.log('isAlreadyApproved--->', isAlreadyApproved)
  const [hasFNftApproval, setHasFNftApproval] = useState(isAlreadyApprovedFNFT);

  // STAKING NFT

  // nft_id
  // baseNFTplanId
  // stakeplanid
  const { config: configStakingNFT, error: configStakingNFTerror } =
    usePrepareContractWrite({
      enabled:
        vals?.nft &&
        vals?.period &&
        (isAlreadyApproved || hasNftApproval) &&
        vals?.nft.isFnft == false
          ? true
          : false,
      // enabled: false,
      address: UTILS_ADDRESS,
      abi: CWARUTILS,
      functionName: "stakeNFT",
      // args: [vals?.nft?.nft_id, vals?.nft?.base_nft_id, vals?.period],
      args: [vals?.nft?.nft_id, vals?.period],
    });

  const {
    data: stakeData,
    isLoading: stakeLoading,
    isSuccess: stakingSuccess,
    write: stakeNFT,
  } = useContractWrite({
    ...configStakingNFT,
    onSuccess: async (data) => {
      // console.log('Staking started', data)
      //stake trx
      const trx = {
        contract: UTILS_ADDRESS,
        type: "staking",
        transactionHash: data.hash,
        nft_id: vals?.nft?.nft_id,
        base_nft_id: vals?.nft.base_nft_id,
        stake_plan: vals?.period,
        name: baseNfts[vals?.nft.base_nft_id - 1]["name"],
        series: baseNfts[vals?.nft.base_nft_id - 1]["current_series"],
        from: address,
        to: UTILS_ADDRESS,
        isFnft: false, // or true,
        amount: 1, // or stake_amount
        sponsor_id: vals?.nft?.sponsor_id,
        user_id: user ? user.user_id : null,
        wallet_address: address,
        timestamp: Math.floor(Date.now() / 1000),
        status: "pending",
      };
      // console.log('trx////// ', trx)
      await axios
        .post("user/add_transaction", trx)
        .then((res) => {
          // console.log(res.data)
          // refresh my nfts
        })
        .catch((error) => {
          // console.log('error:', error)
        });

      // console.log('Await for blockchain confirmation...')
      await data.wait();

      axios
        .post("user/stake", { transactionHash: data.hash })
        .then((res) => {
          // console.log('STAKING SUCCESS')
          // console.log(res.data)
          setProccessOnStore(false);
          _getMyNfts();
          setProgress(false);

          stateSetMessege({
            title: `Staking success`,
            render: () => (
              <p>{`NFT #${vals?.nft?.nft_id} successfully staked for ${
                basePlans.find((_) => _.plan_id == vals?.period)?.["title"]
              }`}</p>
            ),
            type: "success",
          });
        })
        .catch((error) => {
          // console.log('error:', error)
          setProgress(false);
        });
    },
    onError(error) {
      // console.log('Error stakeNFT ----->', error)
      setProgress(false);
      setProccessOnStore(false);
    },
  });

  // STAKING FNFT
  // id
  // amount
  // stakeplanid

  const { config: configStakingFNFT, error: configStakingFNFTError } =
    usePrepareContractWrite({
      enabled:
        vals?.nft &&
        vals?.period &&
        vals?.shareAmount &&
        (isAlreadyApprovedFNFT || hasFNftApproval) &&
        vals?.nft.isFnft == true
          ? true
          : false,
      // enabled: false,
      address: UTILS_ADDRESS,
      abi: CWARUTILS,
      functionName: "stakeFractionalNFT",
      args: [vals?.nft?.nft_id, vals?.shareAmount, vals?.period],
    });

  // console.log('configStakingFNFTError', configStakingFNFTError)
  const {
    data: stakeDataFNFT,
    isLoading: stakeLoadingFNFT,
    isSuccess: stakingSuccessFNFT,
    write: stakeFNFT,
  } = useContractWrite({
    ...configStakingFNFT,
    onSuccess: async (data) => {
      // console.log('Stake hash generated', data)

      const trx = {
        contract: UTILS_ADDRESS,
        type: "staking",
        transactionHash: data.hash,
        nft_id: vals?.nft?.nft_id,
        base_nft_id: vals?.nft.base_nft_id,
        stake_plan: parseInt(vals?.period),
        name: baseNfts[vals?.nft.base_nft_id - 1]["name"] + "F-Token",
        series: baseNfts[vals?.nft.base_nft_id - 1]["current_series"],
        from: address,
        to: UTILS_ADDRESS,
        isFnft: true,
        amount: vals?.shareAmount,
        sponsor_id: vals?.nft.sponsor_id,
        user_id: user ? user.user_id : null,
        wallet_address: address,
        timestamp: Math.floor(Date.now() / 1000),
        status: "pending",
      };

      // console.log('trx ////// ', trx)
      await axios
        .post("user/add_transaction", trx)
        .then((res) => {
          // console.log(res.data)
        })
        .catch((error) => {
          // console.log('error:', error)
        });

      // console.log('Await for blockchain confirmation...')
      await data.wait();

      axios.post(`/user/stake`, { transactionHash: data.hash }).then((res) => {
        if (res.data.status) {
          setProgress(false);
          _getMyNfts();
          // console.log(res.data)
          // console.log('F-NFT staking success')
          setProccessOnStore(false);

          stateSetMessege({
            // title: `NFT fractionalization success`,
            title: `F-NFT staking success`,
            render: () => (
              <p>{`${vals?.shareAmount} ${
                baseNfts[vals?.nft.base_nft_id - 1]["name"] + "F-Token"
              } (#${vals?.nft?.nft_id}) tokens successfully staked`}</p>
              // <p>{`NFT #${vals?.nft?.nft_id} successfully fractionalized into ${vals?.shareAmount} tokens`}</p>
            ),
            type: "success",
          });
          setVals();
        } else {
          setProgress(false);
        }
      });
    },
    onError(error) {
      // console.log('Error stakeDataFNFT ->', error)
      setProgress(false);
      setProccessOnStore(false);
    },
  });

  // APPROVAL
  const { config: configApprovel } = usePrepareContractWrite({
    // enabled: !isAlreadyApproved,
    address: NFT_ADDRESS,
    abi: CWARNFT,
    functionName: "setApprovalForAll",
    args: [UTILS_ADDRESS, true],
  });

  // !isAlreadyApproved && console.log('isAlreadyApproved--->', isAlreadyApproved)

  const {
    data,
    isLoading,
    isSuccess: transferSuccess,
    write: approve,
  } = useContractWrite({
    ...configApprovel,
    onSuccess: async (data) => {
      await data.wait();
      // console.log('Success approval, going to stake NFT', data)
      // stakeNFT?.()
      var _fetch = await refetch?.();
      if (_fetch.isSuccess) {
        setHasNftApproval(_fetch.data);
        setShowStakeConfirm(true);
      }
      setProgress(true);
    },
    onError(error) {
      // console.log('Error approve', error)
      setProgress(false);
    },
  });

  // APPROVAL
  const { config: configApprovelFNFT } = usePrepareContractWrite({
    // enabled: !isAlreadyApproved,
    address: FNFT_ADDRESS,
    abi: CWARFRACTIONAL,
    functionName: "setApprovalForAll",
    args: [UTILS_ADDRESS, true],
  });

  const {
    data: dataFNFT,
    isLoading: isLoadingFNFT,
    isSuccess: successApprovelFNFT,
    write: approveFNFT,
  } = useContractWrite({
    ...configApprovelFNFT,
    onSuccess: async (data) => {
      await data.wait();
      // console.log('Success approval, going to stake FNFT', data)
      // stakeFNFT?.()
      var _fetch = await f_refetch?.();
      if (_fetch.isSuccess) {
        setHasFNftApproval(_fetch.data);
        // stakeFNFT?.()
        setShowStakeConfirm(true);
      }
      setProgress(true);
    },
    onError(error) {
      // console.log('Error approveFNFT --->', error)
      setProgress(false);
    },
  });

  // Contract Error handling
  useEffect(() => {
    var _error =
      vals?.nft?.isFnft == true
        ? configStakingFNFTError
        : configStakingNFTerror;
    var ERROR = getErrorFromContract(_error);

    if (ERROR) {
      setMessege({
        type: "error",
        message: ERROR.replace("execution reverted: ", ""),
      });
      if (ERROR.includes("insufficient balance for transfer")) {
        setErrors({
          shareAmount: `Not enough ${vals?.nft.name} balance to execute stake`,
        });
      }

      if (ERROR.includes("Staking plan should be the same for all stakes")) {
        setErrors({
          period: `Staking plan should be the same for all stakes`,
        });
      }
    }
  }, [configStakingFNFTError, configStakingNFTerror]);

  const onSubmit = () => {
    if (progress) return; // prevent double clicks

    if ((stakingSuccess || stakingSuccessFNFT) && stateMessage) {
      navigate(`/profile`);
      return;
    }

    setErrors({});
    var _errors = {};
    if (!vals?.nft) {
      Object.assign(_errors, { nft: "NFT is required" });
      setErrors(_errors);
      return;
    }
    if (!vals?.period) {
      Object.assign(_errors, { period: "Staking period is required" });
      setErrors(_errors);
      return;
    }
    if (vals?.nft && vals?.nft.isFnft == true) {
      var _error =
        vals?.shareAmount > vals.nft.amount
          ? "Number of Fractions must be less than total F-NFTS"
          : !vals?.shareAmount
          ? "Number of Fractions is required"
          : null;
      if (_error) {
        Object.assign(_errors, { shareAmount: _error });
        setErrors(_errors);
        return;
      }
    }

    if (vals?.nft.isFnft == false) {
      setProgress(true);
      if (isAlreadyApproved) {
        // stakeNFT?.()
        setShowStakeConfirm(true);
      } else {
        // approve?.()
        setShowApprovalConfirm({ type: "nft", show: true });
      }
    } else if (vals?.nft.isFnft == true) {
      // console.log('Checking for FNFT approval', isAlreadyApprovedFNFT)
      setProgress(true);
      if (isAlreadyApprovedFNFT) {
        // stakeFNFT?.()
        setShowStakeConfirm(true);
      } else {
        // approveFNFT?.()
        setShowApprovalConfirm({ type: "fnft", show: true });
      }
    }
  };

  var thumb_name =
    baseNfts &&
    baseNfts[vals?.nft?.base_nft_id - 1]?.name?.replace(" ", "-").toLowerCase();

  var thumb_path =
    vals?.nft?.isFnft === true
      ? `/f_thumbs/${thumb_name}-f.png`
      : `/thumbs/${thumb_name}.png`;

  return (
    <>
      <Outlet />
      <div
        id="sidebar"
        className="bg-light_bg dark:bg-dark_bg bg-no-repeat bg-cover pb-40"
      >
        <Header title="Stake" />
        <div className="sm:flex justify-center mt-10 2xl:px-[15%] px-[5%]">
          <div className="backdrop-blur-md bg-[#ffffff90] rounded-[25px] flex-1 app-shadow text-lg text-slate-700 tracking-wider  dark:bg-[#0d0d0d50] dark:text-[#c9c9c9]">
            <h3 className="p-8 pb-0 font-bold text-center text-2xl">
              NFT Staking
            </h3>

            <div className="p-10 w-full">
              <>
                <h3 className="font-normal">Select your NFT</h3>
                <DropDown
                  _key="nft_picker"
                  placeholder="Select"
                  data={
                    mynfts
                      ? mynfts.map((_) => ({
                          value: _.nft_id,
                          title: `#${_.nft_id} - ${_.name} ${
                            _.amount > 1
                              ? "(" +
                                _.owners.find(
                                  (wallet) =>
                                    wallet.wallet_address == user?.address
                                )?.balance +
                                ")"
                              : ""
                          }`,
                        }))
                      : []
                  }
                  onChage={(val) => {
                    setVals({
                      ...vals,
                      nft: mynfts.filter((_) => _.nft_id == val)[0],
                    });
                    delete errors["nft"];
                    delete errors["nft_id"];
                  }}
                  error={errors?.nft}
                  loading={loading}
                />

                {errors?.nft && (
                  <p className="text-xs text-red-600">* {errors.nft}</p>
                )}
              </>

              {/* ADD NFT */}
              {address && (
                <div className="text-sm text-blue-600 cursor-pointer float-right flex">
                  <span className="material-symbols-outlined text-sm">add</span>
                  <p onClick={() => setShowNftAddModal(true)}> Add your NFT</p>
                </div>
              )}

              <h3 className="font-normal mt-8">Select staking period</h3>
              <DropDown
                _key="nft_plan"
                placeholder="Select"
                data={
                  basePlans &&
                  basePlans.map((_, idx) => ({
                    value: _.plan_id,
                    title: _.title,
                  }))
                }
                onChage={(val) => {
                  setVals({ ...vals, period: val });
                  delete errors["period"];
                }}
                error={errors?.period}
                loading={basePlans ? false : true}
              />
              {errors?.period && (
                <p className="text-xs text-red-600">* {errors.period}</p>
              )}
              {vals?.nft && vals?.nft.isFnft == true && (
                <>
                  <h3 className="font-normal mt-8">Number of Fractions</h3>
                  <input
                    placeholder={vals?.nft?.amount}
                    className="shadow-xl shadow-slate-200 dark:shadow-[#00000040] w-full p-3 my-2 rounded-lg focus:outline-none focus:ring focus:ring-slate-300 dark:bg-[#2e2e2e]"
                    onChange={(e) => {
                      delete errors?.["shareAmount"];
                      // vals?.nft.amount
                      if (
                        e.target.value >
                        vals.nft.owners.find((_) => _.wallet_address == address)
                          ?.balance
                      ) {
                        var error = `Amount should be less than total number of fractions (${vals?.nft.balance})`;
                        setMessege({ message: error });
                        setErrors({ shareAmount: error });
                      } else if (e.target.value <= 0) {
                        var error = `Amount shouldn't be less than 0`;
                        setMessege({ message: error });
                        setErrors({ shareAmount: error });
                      } else {
                        setMessege();
                        setErrors({});
                      }
                      setVals({
                        ...vals,
                        shareAmount: e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1"),
                      });
                    }}
                    value={vals?.shareAmount ?? ""}
                    type="text"
                  />
                  {vals?.nft?.owners && (
                    <p className="text-xs text-blue -600">
                      Balance :{" "}
                      {
                        vals.nft.owners.find((_) => _.wallet_address == address)
                          ?.balance
                      }
                    </p>
                  )}
                  {errors?.shareAmount && (
                    <p className="text-xs text-red-600">
                      * {errors.shareAmount}
                    </p>
                  )}
                </>
              )}
              <div>
                {baseNfts && vals?.period && vals?.nft && (
                  <div className="p-2 rounded-lg mt-4">
                    <p className="text-sm text-slate-500 font-light">
                      #{baseNfts[vals?.nft.base_nft_id - 1]["current_series"]}{" "}
                      Series
                    </p>
                    Reward
                    {vals?.shareAmount > vals?.nft.amount &&
                    vals?.nft.isFnft ? null : (
                      <>
                        <p className="text-2xl font-bold text-green-600">
                          <span className="text-sm text-slate-500 font-light">
                            {vals?.nft.isFnft && vals?.shareAmount // if fractionolized nft
                              ? (
                                  (baseNfts[vals?.nft.base_nft_id - 1][
                                    "rewards"
                                  ][vals?.period] *
                                    vals?.shareAmount) /
                                  vals?.nft.amount /
                                  250
                                ).toFixed(4)
                              : baseNfts[vals?.nft.base_nft_id - 1]["rewards"][ // else
                                  vals?.period
                                ] / 250}{" "}
                            CC / Year
                          </span>
                        </p>
                        <p className="text-2xl font-bold text-green-600">
                          {vals?.nft.isFnft && vals?.shareAmount // if fractionolized nft
                            ? (baseNfts[vals?.nft.base_nft_id - 1]["rewards"][
                                vals?.period
                              ] *
                                vals?.shareAmount) /
                              vals?.nft.amount
                            : baseNfts[vals?.nft.base_nft_id - 1]["rewards"][ // else
                                vals?.period
                              ]}{" "}
                          CWAR Tokens
                          <span className="text-sm text-slate-500 font-light">
                            {" "}
                            / Year
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>

              {messege?.message && (
                <p
                  className={`text-[#f56918] bg-[#f5691810] px-5 py-2 text-sm rounded-lg mt-4`}
                >
                  {messege.message}
                </p>
              )}
              <Button
                // title="Stake"
                title={
                  stakeLoading || stakeLoadingFNFT || progress
                    ? "proccessing..."
                    : (stakingSuccess || stakingSuccessFNFT) && stateMessage
                    ? "NFT Staked"
                    : "Stake"
                }
                loading={
                  stakeLoading ||
                  stakeLoadingFNFT ||
                  progress ||
                  isLoadingApproval ||
                  isLoadingApprovalFNFT
                }
                type={progress ? "warn" : transferSuccess ? "success" : "info"}
                onClick={onSubmit}
                className="my-10 py-2"
                disabled={Object.keys(errors).length !== 0}
              />
              <p className="text-xs text-center">
                Privacy Policy and Terms of Service apply.
              </p>
            </div>
          </div>
          <StakingDetails nft={vals?.nft?.base_nft_id} planId={vals?.period} />
        </div>

        {/* MY STAKES */}
        <h3 className="font-bold text-2xl text-slate-700 my-8 2xl:px-[15%] px-[5%] dark:text-[#c9c9c9]">
          My Stakes
        </h3>
        <div className="2xl:px-[15%] px-[5%] overflow-x-scroll scrollbar-hide">
          <div className="bg-white rounded-[25px] app-shadow min-w-[900px]  dark:bg-[#0d0d0d50] dark:text-[#c9c9c9]">
            {/* HEADER */}
            <div className="flex row items-center justify-between px-10 py-4 text-slate-500">
              <p className="flex-1 text-left text-sm">NFT</p>
              <p className="flex-1 text-left text-sm">NFT Name</p>
              <p className="flex-1 text-left text-sm">Fraction</p>
              <p className="flex-1 text-left text-sm">Staking Date</p>
              <p className="flex-1 text-left text-sm">Term</p>
              <p className="flex-1 text-left text-sm">Maturity</p>
              <p className="flex-1 text-left text-sm">Vesting Date</p>
            </div>
            {myStakedNfts && myStakedNfts.length > 0 ? (
              myStakedNfts
                .filter((_) => _.is_staked == true)
                .map((nft, key) => {
                  var staked_plan = basePlans?.find(
                    (_) => _.plan_id == nft.staked_plan
                  );

                  const year = 60 * 60 * 24 * 365;
                  const diff_in_years =
                    (new Date().getTime() / 1000 - nft.staked_time) / year;
                  const vesting_duration = Math.ceil(diff_in_years);
                  const vesting_date = new Date(
                    (nft.staked_time + vesting_duration * year) * 1000
                  );
                  if (!baseNfts) return;
                  // var thumb_name = nft?.name.replace(" ", "-").toLowerCase();
                  const thumb_path =
                    nft.isFnft === true
                      ? `/f_thumbs/${nft?.name
                          .replace(" ", "-")
                          .toLowerCase()}-f.png`
                      : `/thumbs/${nft?.name
                          .replace(" ", "-")
                          .toLowerCase()}.png`;

                  return (
                    <div key={"list_" + key}>
                      <div className="flex row items-center justify-between border-t-[1px] dark:border-[#333] border-slate-100 px-10  text-slate-600 dark:text-[#c9c9c9]">
                        <div className="flex-1">
                          <img
                            className="rounded-lg w-14 m-4 hover:scale-105 transition"
                            // src={baseNfts[nft.base_nft_id - 1].url}
                            // src="/nft_alt.png"
                            src={thumb_path}
                            // onClick={props?.onCardClick}
                          />
                        </div>
                        <p className="flex-1 text-left">
                          #{nft.nft_id}{" "}
                          {nft.isFnft === true ? (
                            <span className="text-blue-600">
                              {baseNfts[nft.base_nft_id - 1].name} F-Token
                            </span>
                          ) : (
                            baseNfts[nft.base_nft_id - 1].name
                          )}
                        </p>
                        {/* <p className="flex-1 text-left">
                          {nft.isFnft
                            ? nft.staked_amount + '/' + nft.amount
                            : '•'}
                        </p> */}
                        <p className="flex-1 text-lef">
                          {nft?.staked_amount + "/" + nft?.amount}
                        </p>
                        <p className="flex-1 text-lef">
                          {new Date(
                            nft.staked_time * 1000
                          ).toLocaleDateString()}
                        </p>
                        <p className="flex-1 text-lef">
                          {staked_plan?.["title"]}
                        </p>
                        <p className="flex-1 text-lef">
                          {nft?.staked_plan == 1
                            ? "Released"
                            : new Date(
                                (nft.staked_time +
                                  basePlans.find(
                                    (plan) => plan.plan_id == nft?.staked_plan
                                  )?.duration) *
                                  1000
                              ).toLocaleDateString()}
                        </p>{" "}
                        <p className="flex-1 text-left">
                          {nft?.staked_plan == 1
                            ? "Vested"
                            : vesting_date.toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  );
                })
            ) : (
              <p className="p-4 text-center text-slate-400 m-auto">
                No Stakes!
              </p>
            )}
          </div>
        </div>

        {showStakeConfirm && (
          <Modal
            // visible={showStakeConfirm}
            visible={showStakeConfirm}
            onSubmit={() => {
              if (vals?.nft.isFnft) {
                stakeFNFT?.();
                setProccessOnStore(true);
                setShowStakeConfirm(false);
              } else {
                stakeNFT?.();
                setProccessOnStore(true);
                setShowStakeConfirm(false);
              }
            }}
            title="Confirm staking"
            onClose={() => {
              setShowStakeConfirm(false);
              setProgress(false);
            }}
            onSubmitTitle="Confirm"
          >
            {showStakeConfirm && vals?.nft && (
              <div className="p-6 block md:min-w-[600px]" >
                <div className="flex items-center">
                  <div className="m-auto">
                    <img
                      className="overflow-hidden rounded-[30px] hover:scale-105 transition scale-110"
                      // src={baseNfts[vals?.nft?.base_nft_id - 1].url}
                      src={thumb_path}
                    />
                  </div>
                  <div className="p-2 ml-8  w-[50%]">
                    <p className="text-1xl text-slate-400 mt-2 font-light">
                      NFT
                    </p>
                    <p className="text-2xl font-bold border-b-2 border-slate-300 dark:border-slate-600">
                      {vals?.nft.name}
                    </p>

                    {vals?.nft.isFnft && (
                      <>
                        <p className="text-1xl text-slate-400 mt-2 font-light">
                          STAKING AMOUNT
                        </p>
                        <p className="text-2xl font-bold border-b-2 border-slate-300 dark:border-slate-600">
                          {vals?.shareAmount}
                        </p>
                      </>
                    )}

                    <p className="text-1xl text-slate-400 mt-2 font-light">
                      NFT ID
                    </p>
                    <p className="text-2xl font-bold border-b-2 border-slate-300 dark:border-slate-600">
                      #{vals?.nft.nft_id}
                    </p>

                    <p className="text-1xl text-slate-400 mt-2 font-light">
                      Stake Plan
                    </p>

                    <p className="text-2xl font-bold">
                      {
                        basePlans.find((plan) => plan.plan_id == vals?.period)
                          .title
                      }
                    </p>
                    {vals?.period != 1 && (
                      <p className="my-4">
                        You’ve chosen to stake your NFT. It will be{" "}
                        <span className="font-bold">LOCKED</span> for{" "}
                        {Math.floor(
                          basePlans.find((plan) => plan.plan_id == vals?.period)
                            .duration /
                            (60 * 60 * 24 * 30)
                        )}{" "}
                        months. You can unstake it{" "}
                        <span className="font-bold">ONLY AFTER</span> that
                        period ends.
                      </p>
                    )}
                  </div>
                </div>

                {messege?.messege && (
                  <p className="text-black p-2 border-2 my-2 text-lg border-orange-200">
                    {messege.messege}
                  </p>
                )}
              </div>
            )}
          </Modal>
        )}

        <Modal
          visible={showApprovalConfirm?.show}
          onSubmit={() => {
            // console.log('showApprovalConfirm--->', showApprovalConfirm)
            if (showApprovalConfirm?.type == "nft") {
              setShowApprovalConfirm();
              approve();
            }
            if (showApprovalConfirm?.type == "fnft") {
              setShowApprovalConfirm();
              approveFNFT();
            }
          }}
          title="Confirm Approval?"
          onClose={() => {
            setShowApprovalConfirm();
            setProgress(false);
          }}
          onSubmitTitle="Confirm"
          buttonType="warn"
        >
          <div className="p-6 block md:min-w-[600px]">
            <div className="flex items-center">
              <span className="material-symbols-outlined text-8xl dark:white text-[#f56918] mx-6">
                warning
              </span>
              <p className="mr-4">
                Allow access to and transfer your NFTs from the
                Carbon War collection? {"\n"}
                You're granting access to all your Carbon War NFTs, including any you might
                own in the future. This permission is required to allow for Staking on a platform,
                this approval can be revoked when you are no longer staking.
              </p>
            </div>
            {messege?.messege && (
              <p className="text-black p-2 border-2 my-2 text-lg border-orange-200">
                {messege.messege}
              </p>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
}
