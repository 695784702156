import React from "react";
import { Button } from "../../components";

export default function NftFlipCard(props) {
  const showProgress = props?.transferSuccess || props?.progress;

  const price =
    props?.price < 1000
      ? props?.price
      : props?.price > 250 * 1000
      ? "1M"
      : props?.price / 1000 + "K";
  var thumb_name = props?.name.replace(" ", "-").toLowerCase();
  return (
    <section>
      <div className="group cursor-pointer hover-trigger mx-2">
        <div
          className="relative transform transition ease-in-out [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] duration-1000 delay-[var(--delay)]"
          style={{ "--delay": 0.1 + "s" }}
          key={"index"}
        >
          <div className="bg-transparent dark:bg-transparent rounded-[30px] m-4 h-[270px]  overflow-hidden">
            <img
              src={`/thumbs/${thumb_name}.png`}
              // src={props?.url}
              // className="h-[200px] w-[200px]"
              onClick={props?.onCardClick}
              className="h-full w-full object-fill scale-110"
            />
          </div>
          <div
            className="bg-gray-50 dark:bg-[#2e2e2e] dark:text-[#c9c9c9] shadow-inner	text-slate-700 overflow-hidden  border-slate-300 rounded-[40px] absolute inset-0 m-4 mt-0 h-[270px] [backface-visibility:hidden] [transform:rotateY(180deg)]"
            style={{ boxShadow: "inset 0px -1px 10px rgba(0, 0, 0, 0.6)" }}
          >
            {/* <img
                // src={props?.url}
                src="/nft_alt.png"
                className="h-12 w-12  grayscale m-auto"
                onClick={props?.onCardClick}
              /> */}
            <div className="p-4 pr-2 text-sm" onClick={props?.onCardClick}>
              <div className="row flex items-center">
                <div>
                  <p className="text-lg font-bold">{props?.name}</p>
                  <p className="text-base font-bold">${price}</p>
                </div>
                <p className="ml-2 text-[10px] text-green-600 leading-3	">
                  Carbon certificate Earn up to {props.rewards["4"] / 250}/yr
                </p>
              </div>
              <p className=" font-bold italic">
                <br></br>
                Current Series - #{props?.current_series}
                <br></br>
                Total Supply : {props?.totalSupply}
                <br></br>
              </p>
              {(props?.minted * 100) / props?.totalSupply > 50 && (
                <p className="font-bold text-green-600">Over 50% Sold</p>
              )}
              {props?.nextSeriesDetails &&
                (props?.minted * 100) / props?.totalSupply > 75 && (
                  <p className="font-bold italic">
                    <br></br>
                    Next series - #{props?.nextSeriesDetails.id}
                    <br></br>
                    Total Supply : {props?.nextSeriesDetails.totalSupply}
                    <br></br>
                    Price : ${props?.nextSeriesDetails.price}
                  </p>
                )}
            </div>
          </div>
        </div>

        <Button
          title={props?.mintingError ? 'Mint failed!' :
            props?.progress
              ? "proccessing..."
              : props?.transferSuccess == true
              ? "NFT Minted"
              : props?.minted >= props?.totalSupply
              ? `⠀Sold out ⠀`
              : "Buy now"
          }
          className={`mx-10 absolute -translate-y-[70%] ${
            showProgress ? "-translate-x-[15px]" : " "
          } ${showProgress ? "showEver" : "hover-target"}`}
          rightArrow={props?.minted < props?.totalSupply}
          // className={`mx-10 absolute py-[-60px] translate-y-[-70%] translate-x-[18%] ${showProgress ? 'showEver' : 'hover-target'}`}
          onClick={
            props?.minted >= props?.totalSupply ? null : props?.onPayClick
          }
          loading={props?.progress}
          type={
            props?.mintingError
              ? "error"
              : props?.progress || props?.minted >= props?.totalSupply
              ? "warn"
              : props?.transferSuccess
              ? "success"
              : "info"
          }
        />
        <div className="mt-8 dark:text-[#c9c9c9]">
          <h2 className="text-center font-medium uppercase">{props?.name}</h2>
          <p className="text-center font-bold text-black-800 text-lg">
            ${price} {/* <span className="font-light">USDT</span> */}
          </p>
        </div>
      </div>
    </section>
  );
}
