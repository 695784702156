const user = localStorage.getItem("@user");
const showApprovalBar = localStorage.getItem("@SHOW_APPROVAL_BAR");
const polygonModal = localStorage.getItem("@POLYGON_MODAL");
export const initialState = {
  total: 0,
  products: [],
  user: user ? JSON.parse(user) : null,
  showRegModal: false,
  showSignModal: false,
  baseNfts: null,
  basePlans: null,
  message: null,
  showNftAddModal: false,
  baseCurrencyValue: 0,
  showApproval: showApprovalBar,
  proccessing: false,
  gotoDashboard: true,
  polygonModal: polygonModal,
};

const appReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "SIGNIN":
      // console.log('SIGNIN', payload)
      return {
        ...state,
        user: payload,
      };
    case "SHOWREGMODAL":
      return {
        ...state,
        showRegModal: payload,
      };
    case "SHOWSIGNMODAL":
      return {
        ...state,
        showSignModal: payload,
      };
    case "SETBASENFTS":
      return { ...state, baseNfts: payload };
    case "SETBASEPLANS":
      return { ...state, basePlans: payload };
    case "SETMESSAGE":
      return { ...state, message: payload };
    case "SHOWNFTADDMODAL":
      return { ...state, showNftAddModal: payload };
    case "SETBASCURRVAL":
      return { ...state, baseCurrencyValue: payload };
    case "SETDISAPPROVALBANNER":
      return { ...state, showApproval: payload };
    case "SETPROCCESS":
      return { ...state, proccessing: payload };
    case "SETGOTODASHBOARD":
      return { ...state, gotoDashboard: payload };
    case "POLYGON_MODAL":
      return { ...state, polygonModal: payload };
    default:
      throw new Error(`No case for type ${type} found in shopReducer.`);
  }
};

export default appReducer;
