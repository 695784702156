import React from 'react'
import './css/drop-down.css'
const { useState, useEffect } = React

const Dropdown = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(props?.value)

  const toggleDropdown = () => props?.loading ? null : setOpen(!isOpen)

  const handleItemClick = (id) => {
    // selectedItem == id ? setSelectedItem(null) : setSelectedItem(id)
    setSelectedItem(id)
    setOpen(false)
    props?.onChage?.(id)
  }



  return (
    <div className={`dropdown ${props?.class} `}>
      <div
        className={`dropdown-header border-[3px] dark:border-[0px] dark:text-[#c9c9c9] dark:bg-[#333] rounded-[10px] ${isOpen ? 'border-slate-300 ' : 'border-white'
          } ${props?.error ? 'border-red-300 ' : ''} ${props?.data?.length == 0 ? 'text-slate-300' : 'text-slate-800'}`}
        onClick={toggleDropdown}
      >
        {props?.icon && <img
          className="rounded-2xl w-5 h-5 mr-2"
          src={`/coins/${props?.placeholder}.png`}
        />}

        {selectedItem
          ? props?.data?.find((item) => item.value == selectedItem)?.title
          : props?.placeholder ?? 'Select your destination'}
        {!props?.loading ? <span className="material-symbols-outlined">{isOpen ? 'close' : 'expand_more'}</span> :
          <span className="material-symbols-outlined animate-spin text-slate-400">refresh</span>}
      </div>
      <div className={`dropdown-body ${isOpen && 'open'}`}>
        {props?.data?.map((item, key) => (
          <div
            key={'dropdown_' + key}
            className="dropdown-item bg-[#fff] dark:bg-[#333] dark:text-[#c9c9c9] flex items-center"
            onClick={(e) => handleItemClick(e.target.id)}
            id={item.value}
          >
            {props?.icon ?
              <img
                className="rounded-2xl w-3 h-3 mx-2"
                src={`/coins/${item.value}.png`}
              />
              : <span
                className={`dropdown-item-dot ${item.value == selectedItem && 'selected'
                  }`}
              >
                •{' '}
              </span>}
            {item.title}
          </div>
        ))}
      </div>
    </div>
  )
}

// ReactDOM.render(<Dropdown />, document.getElementById('app'))
export default Dropdown
