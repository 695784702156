import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Button, DropDown, Header, Modal } from "../components";
import useStore from "../store/AppContext";
import { useEffect } from "react";
import { adminInstance as axios } from "../axios";
import { useAccount } from "wagmi";

const ModifyButtons = (props) => {
  const [confimed, setConfimed] = useState(false);
  const validationError =
    props?.error ||
    props?.confimMessage.includes("undefined") ||
    props?.confimMessage.includes("null");
  if (!props?.show) return;
  return (
    <>
      <Button
        onClick={() => setConfimed(true)}
        title={props?.submitTitle ?? "Update"}
        className={"float-right " + props?.class}
        rightArrow={false}
        loading={props?.loading}
        type="error"
        disabled={props?.disabled}
      />
      {props?.onCancel && (
        <Button
          onClick={props?.onCancel}
          title="Cancel"
          className="float-right bg-gray-400 mr-2"
          rightArrow={false}
          type="warn"
        />
      )}

      <Modal
        // visible={showApprovalConfirm?.show}
        visible={confimed}
        onSubmit={() => {
          setConfimed(false);
          props?.onSubmit();
        }}
        title="Confirm updation"
        onClose={() => {
          setConfimed(false);
        }}
        onSubmitTitle="Confirm"
        buttonType={validationError ? "error" : "warn"}
        buttonDisabled={validationError}
      >
        <div className="p-6 block w-[600px]">
          <div className="flex items-center">
            <span className="material-symbols-outlined text-6xl dark:white text-[#f56918] mx-2">
              warning
            </span>
            <pre className="mr-4">{props?.confimMessage}</pre>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default function Admin() {
  const [newVals, setNewVals] = useState({
    aboutEdit: false,
    stakePlanId: 1,
  });
  const [loading, setLoading] = useState(false);
  const state = useStore();
  var currentSeries = newVals?.allSeries?.[newVals?.currentSeries];
  // console.log("--->", currentSeries);
  const { address } = useAccount();

  useEffect(() => {
    _getAllSeries();
  }, [newVals.activeNft]);

  useEffect(() => {
    _getCommonfields();
  }, []);

  useEffect(() => {
    if (state?.baseNfts && newVals?.activeNft) {
      var nft = state?.baseNfts?.find?.((_) => _.id == newVals?.activeNft.id);
      setNewVals((prev) => ({ ...prev, activeNft: nft }));
    }
  }, [state.baseNfts]);

  const _getAllSeries = async () => {
    if (!newVals?.activeNft) return;
    axios
      .get(`admin/getSeriesdetails`, {
        params: {
          base_nft_id: newVals?.activeNft?.id,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setNewVals({ ...newVals, ...res.data.data });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _getCommonfields = async () => {
    axios
      .get(`admin/getCommonfields`)
      .then((res) => {
        if (res.data.status) {
          setNewVals({
            ...newVals,
            ...res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _editabout = async () => {
    setLoading(true);
    var body = {
      base_nft_id: newVals.activeNft.id,
      new_about: newVals?.about,
    };
    axios
      .post(`admin/editabout`, body)
      .then((res) => {
        if (res.data.status) {
          state.getBaseNfts();
          setNewVals({
            ...newVals,
            about: null,
            aboutEdit: false,
            activeNft: null,
          });
          state?.setMessege({
            title: `About edited successfully`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
        } else {
          state?.setMessege({
            title: `Editing about failed!`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const _addSeriesdetails = async () => {
    var body = {
      base_nft_id: newVals.activeNft.id,
      series_number: newVals?.series_number ?? currentSeries?.id,
      title: newVals?.title ?? currentSeries?.title,
      price: newVals?.price ?? currentSeries?.price,
      totalSupply: newVals?.totalSupply ?? currentSeries?.totalSupply,
    };
    axios
      .post(`admin/addSeriesdetails`, body)
      .then((res) => {
        console.log("_addSeriesdetails:", res.data);
        state.getBaseNfts();

        if (res.data.status) {
          setNewVals({
            ...newVals,
            activeNft: null,
            seriesAdd: false,
            seriesEdit: false,
            series_number: null,
            title: null,
            price: null,
            totalSupply: null,
            currentSeries: Object.keys(newVals?.allSeries)?.length,
          });
          state?.setMessege({
            title: `Adding series success`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
        } else {
          state?.setMessege({
            title: `Adding series Failed`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    _getAllSeries();
  };

  const _editSeriesdetails = async () => {
    var body = {
      base_nft_id: newVals.activeNft.id,
      series_number: newVals?.series_number ?? currentSeries?.id,
      title: newVals?.title ?? currentSeries?.title,
      price: newVals?.price ?? currentSeries?.price,
      totalSupply: newVals?.totalSupply ?? currentSeries?.totalSupply,
    };
    axios
      .post(`admin/editSeriesdetails`, body)
      .then((res) => {
        console.log("_editSeriesdetails:", res.data);
        if (res.data.status) {
          state.getBaseNfts();
          setNewVals({
            ...newVals,
            activeNft: null,
            seriesEdit: false,
            series_number: null,
            title: null,
            price: null,
            totalSupply: null,
            // currentSeries: Object.keys(newVals?.allSeries)?.length-1,
            // currentSeries: null,
          });
          state?.setMessege({
            title: `Series detail updated successfully`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
        } else {
          state?.setMessege({
            title: `Updating series detail failed!`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    _getAllSeries();
  };

  const _updateCurrentSeries = async () => {
    setLoading(true);
    var body = {
      base_nft_id: newVals.activeNft.id,
      new_series_number: currentSeries?.id,
    };
    axios
      .post(`admin/updateCurrentSeries`, body)
      .then((res) => {
        console.log("_updateCurrentSeries:", res.data);
        if (res.data.status) {
          state.getBaseNfts();
          state?.setMessege({
            title: `current series updated successfully`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
        } else {
          state?.setMessege({
            title: `Updating current series failed!`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _deleteSeriesdetails = async () => {
    setLoading(true);
    var body = {
      base_nft_id: newVals.activeNft.id,
      series_number: currentSeries?.id,
    };
    axios
      .post(`admin/deleteSeriesdetails`, body)
      .then((res) => {
        console.log("_deleteSeriesdetails:", res.data);
        if (res.data.status) {
          _getAllSeries();
          state?.setMessege({
            title: `Series deleted successfully`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
        } else {
          state?.setMessege({
            title: `Deleting series failed!`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _forceUnstakenft = async () => {
    setLoading(true);
    var body = {
      address: newVals.forceUnstakeWallet,
      nft_id: newVals.forceUnstakeNft,
    };
    axios
      .post(`admin/forceUnstakenft`, body)
      .then((res) => {
        console.log("_forceUnstakenft:", res.data);
        if (res.data.status) {
          setNewVals({
            ...newVals,
            forceUnstakeWallet: null,
            forceUnstakeNft: null,
            forceUnstakeEdit: false,
          });
          state?.setMessege({
            title: `Force Unstake Success`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
        } else {
          state?.setMessege({
            title: `Force Unstake Failed`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _updateCWARmintamount = async () => {
    setLoading(true);
    var body = {
      base_nft_id: newVals.activeNft.id,
      new_amount: newVals.newCwarMintAmount,
    };
    axios
      .post(`admin/updateCWARmintamount`, body)
      .then((res) => {
        // console.log("_updateCWARmintamount:", res.data);
        if (res.data.status) {
          setNewVals({
            ...newVals,
            // newCwarMintAmount: null,
            newCwarMintAmountEdit: false,
          });
          state?.setMessege({
            title: `CWAR mint amount updated`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
          state.getBaseNfts();
        } else {
          state?.setMessege({
            title: `CWAR mint amount update failed`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _changeStakedplan = async () => {
    var body = {
      address: newVals.changeStakePlanWallet,
      nft_id: newVals.changeStakePlanNft,
      new_stake_plan_id: newVals?.changeStakePlanId,
    };
    axios
      .post(`admin/changeStakedplan`, body)
      .then((res) => {
        console.log("_changeStakedplan:", res.data);
        if (res.data.status) {
        } else {
          state?.setMessege({
            title: `Changing staking plan Failed`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _verifyAdminlogin = async () => {
    var body = {
      username: newVals?.adminUsername,
      password: newVals?.adminPassword,
    };

    axios
      .post(`admin/verifyAdminlogin`, body)
      .then((res) => {
        console.log("_verifyAdminlogin:", res.data);
        if (res.data.status) {
          setNewVals({
            ...newVals,
            adminUsername: null,
            adminPassword: null,
            adminAuth: true,
          });
        } else {
          state?.setMessege({
            title: `Invalid Credentials`,
            render: () => <p>Username or Password invalid!</p>,
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _adminMintNft = async () => {
    setLoading(true);
    // address - wallet address of user (string)
    // base_nft_id - id of base nft (integer)"
    var body = {
      address: newVals?.mintNFTWallet,
      base_nft_id: newVals?.mintNftId,
    };

    axios
      .post(`admin/mintNft`, body)
      .then((res) => {
        console.log("_mintNft:", res.data);
        if (res.data.status) {
          setNewVals({
            ...newVals,
            mintNFTWallet: null,
            mintNftId: null,
            mintNftEdit: true,
          });
          state?.setMessege({
            title: `NFT minted successfully`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
        } else {
          state?.setMessege({
            title: `Minting NFT failed!`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const _updateCWARcommissionAmount = async () => {
    setLoading(true);
    var body = {
      base_nft_id: newVals.activeNft.id,
      new_amount: newVals.newCwarCommissionAmount,
    };
    axios
      .post(`admin/updateCWARcommissionamount`, body)
      .then((res) => {
        console.log("_updateCWARcommissionAmount:", res.data);
        if (res.data.status) {
          setNewVals({
            ...newVals,
            newCwarCommissionAmount: null,
            newCwarCommissionAmountEdit: false,
          });
          state?.setMessege({
            title: `CWAR commission amount updated`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
          state.getBaseNfts();
        } else {
          state?.setMessege({
            title: `CWAR commission amount update failed`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const _updateCWARStakeReward = async () => {
    var body = {
      base_nft_id: newVals.activeNft.id,
      stake_plan_id: newVals.stakePlanId,
      new_reward: newVals.newCwarStakeRewardAmount,
    };

    if (!newVals?.newCwarStakeRewardAmount) {
      state?.setMessege({
        title: `Reward amount required`,
        render: () => <p>CWAR stake plan reward amount required</p>,
        type: "error",
      });
      return;
    }

    setLoading(true);

    axios
      .post(`admin/updateStakerewards`, body)
      .then((res) => {
        console.log("_updateStakerewards:", res.data);
        if (res.data.status) {
          setNewVals({
            ...newVals,
            newCwarStakeRewardAmount: null,
            newCwarStakeRewardAmountEdit: false,
          });
          state?.setMessege({
            title: `CWAR stake plan reward amount updated`,
            render: () => <p>{res.data.message}</p>,
            type: "success",
          });
          setLoading(false);
          state.getBaseNfts();
        } else {
          state?.setMessege({
            title: `CWAR  stake plan reward update failed`,
            render: () => <p>{res.data.message}</p>,
            type: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ADMIN ONLY ACCESS
  if (
    (newVals?.adminAddresses && !newVals?.adminAddresses?.includes(address)) ||
    !address
  ) {
    return <Navigate to="/" />;
  }

  // ADMIN AUTHENTICATION
  if (!newVals?.adminAuth) {
    return (
      <>
        <Outlet />
        <Header title="App Config" hideDashboardButton={true} />
        <div className="p-8 md:w-[50%] m-auto mt-[10%]">
          <p className="text-4xl font-thin mb-4">Admin Passphrase</p>

          <input
            className="p-2 border mb-2 outline-0 w-[97%]"
            placeholder="username"
            value={newVals?.adminUsername ?? ""}
            type="username"
            onChange={(e) => {
              setNewVals({
                ...newVals,
                adminUsername: e.target.value,
              });
            }}
          />
          <div className="flex items-center">
            <input
              className="p-2 border w-full mb-2 outline-0"
              placeholder="password"
              value={newVals?.adminPassword ?? ""}
              type={newVals?.adminShowPassword ? "" : "password"}
              onChange={(e) => {
                setNewVals({
                  ...newVals,
                  adminPassword: e.target.value,
                });
              }}
            />

            {!newVals?.adminShowPassword ? (
              <span
                className="material-symbols-outlined cursor-pointer relative -left-10 -top-1 text-[#0E7FB8]"
                onClick={() =>
                  setNewVals({ ...newVals, adminShowPassword: true })
                }
              >
                lock
              </span>
            ) : (
              <span
                className="material-symbols-outlined cursor-pointer relative -left-10 -top-1 text-[#f84444]"
                onClick={() =>
                  setNewVals({ ...newVals, adminShowPassword: false })
                }
              >
                lock_open
              </span>
            )}
          </div>

          <Button
            title="Enter"
            className="w-2/4 m-auto my-4"
            onClick={() => _verifyAdminlogin()}
            onClick_={() => {
              if (newVals?.adminPassword == "enter") {
              } else {
                state?.setMessege({
                  title: `Authentication Faild!`,
                  render: () => <p>Password is not valid</p>,
                  type: "error",
                });
              }
            }}
            type="warn"
            loading={loading}
            disabled={!newVals?.adminPassword || !newVals?.adminUsername}
          />
        </div>
      </>
    );
  }

  // ADMIN CONFIG
  var thumb_name = newVals?.activeNft?.name?.replace(" ", "-").toLowerCase();
  return (
    <>
      <Outlet />
      <div
        id="sidebar"
        // className="bg-light_bg dark:bg-dark_bg min-h-[100vh] bg-cover bg-no-repeat"
        className="bg-gradient-to-t from-[#f8444420] from-0% via-[#f5691810] via-95% to-transparent to-100%"
      >
        <Header title="App Config" hideDashboardButton={true} />
        <main className="px-[10%] pb-20">
          <p className="text-4xl font-thin mb-4">Edit NFT</p>
          <div className="flex">
            {state?.baseNfts &&
              state.baseNfts.map((nft, key) => {
                var isActive = nft?.id == newVals.activeNft?.id;
                var thumb_name = nft?.name.replace(" ", "-").toLowerCase();
                return (
                  <div
                    key={"NFT_" + key}
                    className="bg-transparent cursor-pointer relative"
                    onClick={() => {
                      setNewVals({ ...newVals, activeNft: nft });
                    }}
                  >
                    <img
                      className={`scale-110 grayscale h-40 hover:object-cover hover:scale-105 transition-all hover:opacity-100 ${
                        isActive ? "grayscale-0 opacity-100" : "opacity-60"
                      }`}
                      // src="/nft_alt.png"
                      // src={nft?.url}
                      src={`/thumbs/${thumb_name}.png`}
                    />
                    <p className="font-bold text-base ml-2 text-center">
                      {nft.name}
                    </p>
                    <p className="font-light text-base ml-2 text-center">
                      ${nft.price}
                    </p>
                    {isActive && (
                      <div className="w-5 h-5  absolute rotate-45 left-[40%] -bottom-7 bg-gradient-to-tl from-transparent from-0% via-transparent via-95% to-orange-500 to-100%" />
                    )}
                  </div>
                );
              })}
          </div>

          {/* //////////////////  Settings section 1 //////////////////////////////////////////////////////  */}
          {newVals?.activeNft && (
            <div className="flex my-4  bg-white rounded-3xl ">
              <div className="w-[20%]">
                <p className="font-bold text-2xl mx-6 mt-12">
                  {newVals?.activeNft.name}
                </p>
                <p className="font-bold text-xl mx-6">
                  ${newVals?.activeNft.price}
                </p>
                <img
                  className="object-contain"
                  src={`/thumbs/${thumb_name}.png`}
                />
              </div>
              <div className="p-4 flex-1">
                <h3 className="font-bold">About</h3>
                <textarea
                  cols="2"
                  rows="15"
                  id="rules"
                  className="w-full p-2 text-sm border outline-0"
                  value={newVals?.about ?? newVals?.activeNft.about}
                  onChange={(e) => {
                    setNewVals({
                      ...newVals,
                      about: e.target.value,
                      aboutEdit: true,
                    });
                  }}
                />
                <ModifyButtons
                  show={newVals?.aboutEdit}
                  classSubmit="float-right border-red-400"
                  classCancel="float-right"
                  onSubmit={() => _editabout()}
                  onCancel={() => {
                    setNewVals({
                      ...newVals,
                      about: newVals?.activeNft.about,
                      aboutEdit: false,
                    });
                    setLoading(false);
                  }}
                  confimMessage="Change NFT description"
                  error={newVals?.about == ""}
                />

                {/* UPDATE CWAR mint amount */}
                <div className="bg-white my-4 flex-1">
                  <h3 className="font-bold">Update CWAR mint amount</h3>
                  <label className="text-slate-500 text-sm">
                    {newVals?.newCwarMintAmount
                      ? "New amount"
                      : "Current amount"}
                  </label>
                  <input
                    className="p-2 border w-full mb-2 outline-0"
                    placeholder="Amount"
                    value={
                      newVals?.newCwarMintAmount ??
                      newVals?.activeNft?.cwar_mint_amount
                    }
                    type="number"
                    onChange={(e) => {
                      setNewVals({
                        ...newVals,
                        newCwarMintAmount: e.target.value,
                        newCwarMintAmountEdit: true,
                      });
                    }}
                  />
                  <ModifyButtons
                    show={newVals?.newCwarMintAmountEdit}
                    classSubmit="float-right border-red-400"
                    onSubmit={_updateCWARmintamount}
                    submitTitle="Set New Amount"
                    onCancel={() => {
                      setNewVals({
                        ...newVals,
                        newCwarMintAmount: null,
                        newCwarMintAmountEdit: false,
                      });
                      setLoading(false);
                    }}
                    confimMessage={`NFT ID: #${newVals?.activeNft.id}\nPlan: ${newVals?.newCwarMintAmount}`}
                    loading={loading}
                  />
                </div>
                {/* end UPDATE CWAR mint amount */}

                {/* UPDATE CWAR commission amount */}
                <div className="bg-white my-4 flex-1">
                  <h3 className="font-bold">Update CWAR commission amount</h3>
                  <label className="text-slate-500 text-sm">
                    {newVals?.newCwarCommissionAmount
                      ? "New amount"
                      : "Current amount"}
                  </label>
                  <input
                    className="p-2 border w-full mb-2 outline-0"
                    placeholder="Amount"
                    value={
                      newVals?.newCwarCommissionAmount ??
                      newVals?.activeNft?.cwar_commission_amount
                    }
                    type="number"
                    onChange={(e) => {
                      setNewVals({
                        ...newVals,
                        newCwarCommissionAmount: e.target.value,
                        newCwarCommissionAmountEdit: true,
                      });
                    }}
                  />
                  <ModifyButtons
                    show={newVals?.newCwarCommissionAmountEdit}
                    classSubmit="float-right border-red-400"
                    onSubmit={_updateCWARcommissionAmount}
                    submitTitle="Set New Amount"
                    onCancel={() => {
                      setNewVals({
                        ...newVals,
                        newCwarCommissionAmount: null,
                        newCwarCommissionAmountEdit: false,
                      });
                      setLoading(false);
                    }}
                    confimMessage={`NFT ID: #${newVals?.activeNft.id}\nPlan: ${newVals?.newCwarCommissionAmount}`}
                    loading={loading}
                  />
                </div>
                {/* end UPDATE CWAR commission amount */}

                {/* UPDATE CWAR Stake Rewards */}
                <h3 className="font-bold">Stake Rewards</h3>
                <div className="bg-gray-50 p-2 rounded-lg pb-12">
                  {newVals?.allSeries && (
                    <DropDown
                      _key="nft_plan"
                      placeholder="Select"
                      data={Object.values(newVals?.activeNft?.rewards).map(
                        (planValue, plan_key) => ({
                          title: state.basePlans[plan_key].title,
                          value: plan_key + 1,
                        })
                      )}
                      onChage={(val) => {
                        setNewVals({ ...newVals, stakePlanId: val });
                      }}
                      error={"errors?.period"}
                      value={newVals?.stakePlanId}
                      loading={false}
                      class="border"
                    />
                  )}

                  <label className="text-slate-500 text-sm">
                    {newVals?.newCwarStakeRewardAmount
                      ? "New reward in CC"
                      : "Current reward in CC"}
                  </label>
                  <input
                    className="p-2 border w-full mb-2 outline-0"
                    placeholder="Amount"
                    value={
                      newVals?.newCwarStakeRewardAmount ??
                      newVals?.activeNft?.rewards[newVals?.stakePlanId] / 250
                    }
                    type="number"
                    onChange={(e) => {
                      setNewVals({
                        ...newVals,
                        newCwarStakeRewardAmount: e.target.value,
                        newCwarStakeRewardAmountEdit: true,
                      });
                    }}
                  />

                  <ModifyButtons
                    show={newVals?.newCwarStakeRewardAmountEdit}
                    classSubmit="float-right border-red-400"
                    onSubmit={_updateCWARStakeReward}
                    submitTitle="Set New Reward"
                    onCancel={() => {
                      setNewVals({
                        ...newVals,
                        newCwarStakeRewardAmount: null,
                        newCwarStakeRewardAmountEdit: false,
                      });
                      setLoading(false);
                    }}
                    confimMessage={`NFT ID: #${newVals?.activeNft.id}\nPlan Id: ${newVals?.stakePlanId}\nPlan new reward amount: ${newVals?.newCwarStakeRewardAmount}`}
                    loading={loading}
                  />
                </div>

                {/* end UPDATE CWAR Stake Rewards */}
              </div>

              <div className="px-4 pb-16 border-l border-l-slate-100 flex-1">
                <div className="flex items-center">
                  <h3 className="font-bold my-4">Series details </h3>
                  <span
                    className="material-symbols-outlined ml-4 cursor-pointer text-blue-500"
                    onClick={() => {
                      setNewVals({
                        ...newVals,
                        seriesAdd: true,
                        currentSeries:
                          Object.keys(newVals?.allSeries)?.length + 1,
                      });
                    }}
                  >
                    add_circle
                  </span>
                </div>
                {newVals?.allSeries && (
                  <DropDown
                    _key="nft_plan"
                    placeholder="Select"
                    data={Object.values(newVals.allSeries).map((_, idx) => ({
                      value: _.id,
                      title: _.title,
                    }))}
                    onChage={(val) => {
                      setNewVals({ ...newVals, currentSeries: val });
                    }}
                    error={"errors?.period"}
                    value={newVals?.currentSeries}
                    loading={false}
                    class="border-red-300 border"
                  />
                )}

                {/* /admin/changeStakedplan */}
                <div className="flex items-center my-4">
                  <ModifyButtons
                    show={true}
                    submitTitle="SET AS CURRENT SERIES"
                    onSubmit={_updateCurrentSeries}
                    confimMessage="Are you sure that you want to change series"
                    class="mr-4"
                  />

                  <ModifyButtons
                    show={true}
                    classSubmit="m-14"
                    submitTitle="DELETE SERIES"
                    onSubmit={_deleteSeriesdetails}
                    confimMessage="You are deleting the series!"
                    disabled={
                      newVals?.allSeries &&
                      Object.keys(newVals.allSeries).length == 1
                    }
                  />
                </div>

                {/* /admin/addSeriesdetails */}
                <label className="text-slate-500 text-sm">Series Number</label>
                <input
                  className="p-2 border w-full mb-2 outline-0"
                  placeholder="Series Number"
                  name="series_number"
                  value={newVals?.series_number ?? currentSeries?.id ?? ""}
                  onChange={(e) => {
                    setNewVals({
                      ...newVals,
                      series_number: e.target.value,
                      seriesEdit: true,
                    });
                  }}
                  disabled={newVals?.seriesAdd ? false : true}
                />
                <br></br>
                <label className="text-slate-500 text-sm">Title</label>
                <input
                  className="p-2 border w-full mb-2 outline-0"
                  placeholder="title"
                  name="title"
                  value={newVals?.title ?? currentSeries?.title ?? ""}
                  onChange={(e) => {
                    setNewVals({
                      ...newVals,
                      title: e.target.value,
                      seriesEdit: true,
                    });
                  }}
                />
                <br></br>
                <label className="text-slate-500 text-sm">Price</label>
                <input
                  className="p-2 border w-full mb-2 outline-0"
                  placeholder="price"
                  name="price"
                  value={newVals?.price ?? currentSeries?.price ?? ""}
                  onChange={(e) => {
                    setNewVals({
                      ...newVals,
                      price: e.target.value,
                      seriesEdit: true,
                    });
                  }}
                />
                <br></br>

                <label className="text-slate-500 text-sm">Total supply</label>
                <input
                  className="p-2 border w-full mb-2 outline-0"
                  placeholder="totalSupply"
                  name="totalSupply"
                  value={
                    newVals?.totalSupply ?? currentSeries?.totalSupply ?? ""
                  }
                  onChange={(e) => {
                    setNewVals({
                      ...newVals,
                      totalSupply: e.target.value,
                      seriesEdit: true,
                    });
                  }}
                />
                <ModifyButtons
                  show={newVals?.seriesEdit || newVals?.seriesAdd}
                  classSubmit="float-right border-red-400"
                  classCancel="float-right"
                  onSubmit={() =>
                    newVals?.seriesAdd
                      ? _addSeriesdetails()
                      : _editSeriesdetails()
                  }
                  submitTitle={newVals?.seriesAdd ? "Add new series" : "Update"}
                  onCancel={() => {
                    setNewVals({
                      ...newVals,
                      seriesEdit: false,
                      seriesAdd: false,
                      series_number: null,
                      title: null,
                      price: null,
                      totalSupply: null,
                      currentSeries: Object.keys(newVals?.allSeries)?.length,
                    });
                    setLoading(false);
                  }}
                  confimMessage={
                    newVals?.seriesAdd
                      ? `Series Number: ${newVals?.series_number}\nTitle: ${newVals?.title} \nPrice: ${newVals?.price}\nTotal supply: ${newVals?.totalSupply}`
                      : `Series Number: ${
                          newVals?.series_number ?? currentSeries?.id
                        }\nTitle: ${
                          newVals?.title ?? currentSeries?.title
                        } \nPrice: ${
                          newVals?.price ?? currentSeries?.price
                        }\nTotal supply: ${
                          newVals?.totalSupply ?? currentSeries?.totalSupply
                        }`
                  }
                  loading={loading}
                />
              </div>
            </div>
          )}

          {/* //////////////////  Settings section 2 //////////////////////////////////////////////////////  */}
          <div className="flex justify-between">
            {/* FORCE UNSTAKE */}
            <div className="bg-white p-4 my-4 flex-1 mr-4 rounded-3xl overflow-hidden">
              <p className="text-4xl font-thin mb-4">Force Unstake NFT</p>
              {/* "address - wallet address of user (string) */}
              {/* nft_id - id of nft to unstake (integer)" */}

              <label className="text-slate-500 text-sm">
                User wallet address
              </label>
              <input
                className="p-2 border w-full mb-2 outline-0"
                placeholder="Address"
                value={newVals?.forceUnstakeWallet ?? ""}
                onChange={(e) => {
                  setNewVals({
                    ...newVals,
                    forceUnstakeWallet: e.target.value,
                    forceUnstakeEdit: true,
                  });
                }}
              />
              <br></br>
              <label className="text-slate-500 text-sm">NFT id</label>
              <input
                className="p-2 border w-full mb-2 outline-0"
                placeholder="NFT ID"
                value={newVals?.forceUnstakeNft ?? ""}
                type="number"
                onChange={(e) => {
                  setNewVals({
                    ...newVals,
                    forceUnstakeNft: e.target.value,
                    forceUnstakeEdit: true,
                  });
                }}
              />
              <ModifyButtons
                show={newVals?.forceUnstakeEdit}
                classSubmit="float-right border-red-400"
                classCancel="float-right"
                onSubmit={_forceUnstakenft}
                submitTitle="Force Unstake"
                onCancel={() => {
                  setNewVals({
                    ...newVals,
                    forceUnstakeWallet: null,
                    forceUnstakeNft: null,
                    forceUnstakeEdit: false,
                  });
                  setLoading(false);
                }}
                confimMessage={`Force unstake \nNFT: #${newVals?.forceUnstakeNft}\nuser: ${newVals?.forceUnstakeWallet}!`}
                loading={loading}
              />
            </div>
            {/* end FORCE UNSTAKE */}

            {/* CHANGE STAKE PLAN */}
            <div className="bg-white p-4 my-4 flex-1 ml-4 rounded-3xl overflow-hidden">
              <p className="text-4xl font-thin mb-4">Change stake plan</p>
              {/* "address - wallet address of user (string) */}
              {/* nft_id - id of nft to unstake (integer)" */}

              <label className="text-slate-500 text-sm">
                User wallet address
              </label>
              <input
                className="p-2 border w-full mb-2 outline-0"
                placeholder="Address"
                value={newVals?.changeStakePlanWallet ?? ""}
                onChange={(e) => {
                  setNewVals({
                    ...newVals,
                    changeStakePlanWallet: e.target.value,
                    changeStakePlanEdit: true,
                  });
                }}
              />
              <br></br>
              <label className="text-slate-500 text-sm">NFT id</label>
              <input
                className="p-2 border w-full mb-2 outline-0"
                placeholder="NFT ID"
                value={newVals?.changeStakePlanNft ?? ""}
                type="number"
                onChange={(e) => {
                  setNewVals({
                    ...newVals,
                    changeStakePlanNft: e.target.value,
                    changeStakePlanEdit: true,
                  });
                }}
              />

              <br></br>
              <label className="text-slate-500 text-sm">
                New stake plan id
              </label>
              <input
                className="p-2 border w-full mb-2 outline-0"
                placeholder="stake plan ID"
                value={newVals?.changeStakePlanId ?? ""}
                type="number"
                onChange={(e) => {
                  setNewVals({
                    ...newVals,
                    changeStakePlanId: e.target.value,
                    changeStakePlanEdit: true,
                  });
                }}
              />
              <ModifyButtons
                show={newVals?.changeStakePlanEdit}
                classSubmit="float-right border-red-400"
                onSubmit={_changeStakedplan}
                submitTitle="Stake Plan"
                onCancel={() => {
                  setNewVals({
                    ...newVals,
                    changeStakePlanWallet: null,
                    changeStakePlanNft: null,
                    changeStakePlanId: null,
                    changeStakePlanEdit: false,
                  });
                  setLoading(false);
                }}
                confimMessage={`User: ${newVals?.changeStakePlanWallet}\nNFT ID: #${newVals?.changeStakePlanNft}\nPlan: ${newVals?.changeStakePlanId}`}
                loading={loading}
              />
            </div>
            {/* end CHANGE STAKE PLAN */}
          </div>

          {/* //////////////////  Settings section 3 //////////////////////////////////////////////////////  */}
          <div className="flex justify-between">
            {/* FORCE UNSTAKE */}
            <div className="bg-white p-4 my-4 flex-1 mr-4 rounded-3xl overflow-hidden">
              {/* Mint CWAR Tokens */}
              <p className="text-4xl font-thin mb-4">Mint CWAR Tokens</p>
              {newVals?.baseTotal &&
                Object.entries(newVals.baseTotal).map((_, idx) => {
                  return (
                    <div
                      className="flex border-b  border-slate-100 text-slate-500 font-thin"
                      key={"year_" + idx}
                    >
                      <p className="px-4 ml-[10%]">{_[0]}</p>
                      <p>
                        <span className="font-bold text-slate-900">
                          {" "}
                          {_[1]}
                        </span>{" "}
                        CWAR
                      </p>
                    </div>
                  );
                })}
              <p className="text-slate-500 text-sm mt-4">CWAR Amount </p>
              {newVals?.baseTotal && (
                <input
                  className="p-2 border w-full mb-2 outline-0 font-bold text-xl"
                  placeholder={`max ${Object?.values(
                    newVals?.baseTotal
                  )?.pop()}`}
                  value={newVals?.cwarMintAmount ?? ""}
                  type="number"
                  onChange={(e) => {
                    var lastYearMintAmount = Object?.values(
                      newVals.baseTotal
                    ).pop();
                    if (e.target.value > lastYearMintAmount) {
                      state?.setMessege({
                        title: `Last base total is ${lastYearMintAmount}`,
                        render: () => (
                          <p>
                            amount shouldn't be greater than{" "}
                            {lastYearMintAmount}
                          </p>
                        ),
                        type: "error",
                      });
                      return;
                    }
                    setNewVals({
                      ...newVals,
                      cwarMintAmount: e.target.value,
                      mintCwarEdit: true,
                    });
                  }}
                />
              )}
              <ModifyButtons
                show={newVals?.mintCwarEdit}
                classSubmit="border-red-400"
                classCancel=""
                onSubmit={() => alert("no function")}
                submitTitle="Mint CWAR"
                onCancel={() => {
                  setNewVals({
                    ...newVals,
                    cwarMintAmount: null,
                    mintCwarEdit: false,
                  });
                  setLoading(false);
                }}
                confimMessage={`!Mint : ${newVals?.cwarMintAmount} CWAR Tokens`}
                loading={loading}
              />
            </div>

            <div className="p-4 my-4 pb-16 flex-1 ml-4 bg-white rounded-3xl overflow-hidden">
              {/* Mint NFT */}
              <p className="text-4xl font-thin mb-4">Mint NFT</p>
              <label className="text-slate-500 text-sm">
                User wallet address
              </label>
              <input
                className="p-2 border w-full mb-2 outline-0"
                placeholder="Address"
                value={newVals?.mintNFTWallet ?? ""}
                onChange={(e) => {
                  setNewVals({
                    ...newVals,
                    mintNFTWallet: e.target.value,
                    mintNftEdit: true,
                  });
                }}
              />
              <br></br>
              <label className="text-slate-500 text-sm">Base NFT id</label>
              <input
                className="p-2 border w-full mb-2 outline-0"
                placeholder="NFT ID"
                value={newVals?.mintNftId ?? ""}
                type="number"
                onChange={(e) => {
                  setNewVals({
                    ...newVals,
                    mintNftId: e.target.value,
                    mintNftEdit: true,
                  });
                }}
              />
              <ModifyButtons
                show={newVals?.mintNftEdit}
                classSubmit="float-right border-red-400"
                classCancel="float-right"
                onSubmit={_adminMintNft}
                submitTitle="Mint NFT"
                onCancel={() => {
                  setNewVals({
                    ...newVals,
                    mintNFTWallet: null,
                    mintNftId: null,
                    mintNftEdit: false,
                  });
                  setLoading(false);
                }}
                confimMessage={`NFT: #${newVals?.mintNftId}\nUser: ${newVals?.mintNFTWallet}`}
                loading={loading}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
