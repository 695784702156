import React, { useEffect, useState } from "react";
import { getProvider } from "@wagmi/core";
import {
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useAccount,
} from "wagmi";
import { Web3Button, useWeb3Modal } from "@web3modal/react";
import { Outlet, useParams } from "react-router-dom";
import axios from "../axios";
import Web3 from "web3";
// import { ethers } from 'ethers'

// import nftABI from '../abi/TestCarbonWarNFT.json' // 0xcAFe03675AF4cDFB936F10ACb91dAD987D91E029 - nft demo polygon
// import TokenABI from '../abi/TestToken.json' // 0x6eaf4cd2559442b1fc8ad467977b7a678ee51913
import USDTTest from "../abi/USDT.json"; // 0x7b43466289198eEfFB422b9d5d03bBB9A53ED045
import useStore from "../store/AppContext";
import { ADMIN_ADDRESS, USDT_ADDRESS, USDC_ADDRESS } from "../config";
import { Button, Header, Modal, Tabs } from "../components";
import { useNavigate } from "react-router-dom";

export default function NftView() {
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(false);

  const { baseNfts, baseCurrencyValue } = useStore();
  const { id } = useParams();
  const nft = baseNfts?.[id - 1];
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    user,
    setShowRegModal,
    setShowSignModal,
    basePlans,
    setMessege: stateSetMessege,
  } = useStore();
  const [showConfirmModal, setshowConfirmModal] = useState(false);

  const [requiredBalance, setRequiredBalance] = useState();
  const { config: configTransferToken } = usePrepareContractWrite({
    // enabled: false,
    enabled: requiredBalance && nft?.price ? true : false,
    address: USDT_ADDRESS,
    abi: USDTTest,
    functionName: "transfer",
    args: [
      ADMIN_ADDRESS,
      Web3.utils.toWei(
        nft?.price
          ? ((baseCurrencyValue == 0
              ? nft?.price
              : nft?.price / baseCurrencyValue
            ).toFixed(6)).toString()
          : "0",
        "mwei"
      ),
    ],
  });

  const {
    data,
    isLoading,
    isSuccess: transferSuccess,
    write: transfer,
  } = useContractWrite({
    ...configTransferToken,
    onSuccess: async (data) => {
      setProgress(true);
      // console.log('trying to mint nft...')
      // mint?.()
      //usdt transfer
      const trx = {
        contract: user.default_payment == "USDT" ? USDT_ADDRESS : USDC_ADDRESS,
        type: "transfer",
        transactionHash: data.hash,
        name: user.default_payment == "USDT" ? "USDT" : "USDC",
        from: address,
        to: ADMIN_ADDRESS,
        amount: (nft.price / baseCurrencyValue).toFixed(6),
        sponsor_id: user?.sponsor_id,
        user_id: user?.user_id,
        wallet_address: address,
        timestamp: Math.floor(Date.now() / 1000),
        status: "pending",
      };
      // console.log('trx ////// ', trx)
      await axios
        .post("user/add_transaction", trx)
        .then((res) => {
          // console.log(res.data)
        })
        .catch((error) => {
          // console.log('error:', error)
        });

      // console.log('Await for blockchain confirmation...')
      await data.wait();

      axios
        .post("user/mint", {
          transactionHash: data.hash,
          to: address,
          base_nft_id: nft?.id,
          nft_series: nft?.current_series,
        })
        .then((res) => {
          // console.log('MINTING SUCCESS')
          // console.log(res.data)
          setProgress(false);
          setshowConfirmModal(false);
        })
        .catch((error) => {
          // console.log('error:', error)
        });
    },
    onError(error) {
      // console.log('Error', error)
      setProgress(false);
    },
  });
  // if (!baseNfts) return

  var thumb_name = nft?.name?.replace(" ", "-").toLowerCase();
  return (
    <>
      <Outlet />
      <div
        id="sidebar"
        className="bg-light_bg dark:bg-dark_bg bg-no-repeat bg-cover pb-40"
      >
        <Header title="NFT Details" />
        {baseNfts && (
          <>
            <div className="sm:flex sm:px-40 sm:pt-20">
              <div className="items-center justify-center flex sm:mr-8 sm:w-[30%] md:scale-105">
                <img
                  // src="/nft_alt.png"
                  src={nft["url"]}
                  className="object-contain	rounded-[25px]"
                  // onClick={props?.onCardClick}
                />
              </div>

              <div className="flex-1">
                <div className="bg-[#ffffff50] dark:bg-[#0d0d0d50] dark:text-[#c9c9c9] rounded-[25px] p-12 app-shadow ">
                  <p className="font-bold text-3xl mb-2">{nft["name"]}</p>
                  <p className="font-bold text-xl">${nft["price"]}</p>
                  <Button
                    className="xl:w-[30%] my-4 py-3"
                    // title="Buy now"
                    title={
                      progress || isLoading
                        ? "proccessing..."
                        : transferSuccess == true
                        ? "NFT Minted"
                        : nft?.minted >= nft?.totalSupply
                        ? `⠀Sold out ⠀`
                        : "Buy now"
                    }
                    onClick={async () => {
                      if (progress || isLoading) return; // prevent double clicks
                      if (user) {
                        setProgress(true);
                        // console.log('0')

                        // CHECK BALANCE OF
                        const {
                          data: {
                            data: { balance },
                          },
                        } = await axios.get(`user/mybalances/${address}`);
                        setRequiredBalance(balance);

                        if (balance[user?.default_payment] < nft.price) {
                          // console.log('1')
                          stateSetMessege({
                            title: `Not enough ${user?.default_payment} Balance`,
                            render: () => (
                              <p>{`${user?.default_payment} balance is : ${
                                balance[user?.default_payment]
                              }`}</p>
                            ),
                            type: "error",
                          });
                          setProgress(false);
                          return;
                        } else {
                          // console.log('2')
                          setshowConfirmModal(true);
                          setProgress(false);
                        }
                        // } else if (address) {
                        // setShowSignModal(true)
                      } else {
                        // console.log('3')
                        // setShowRegModal(true)
                        setProgress(false);
                        // var { data } = await axios.get(`/user/getUser/${address}`)
                        // console.log('hasUser->', data.data)
                        // if (data?.data) {
                        //   setShowSignModal(true)
                        // } else {
                        //   setShowRegModal(true)
                        if (address) {
                          setShowRegModal(true);
                        } else {
                          open?.();
                        }
                        // }
                      }
                    }}
                    loading={progress || isLoading}
                    type={
                      progress || isLoading
                        ? "warn"
                        : transferSuccess
                        ? "success"
                        : "info"
                    }
                  />
                </div>

                <div className="bg-[#ffffff50] dark:bg-[#0d0d0d50] dark:text-[#c9c9c9] rounded-[25px] p-12 py-2 app-shadow mt-8">
                  <p className="font-regular text-xl my-2">Staking Rewards</p>

                  <div>
                    <div className="flex justify-between py-2">
                      <p className="font-thin text-left flex-1 text-slate-500 dark:text-[#c9c9c9]">
                        Period
                      </p>
                      <p className="font-thin text-left flex-1 text-slate-500 dark:text-[#c9c9c9]">
                        Reward
                      </p>
                      <p className="font-thin text-left flex-1 text-slate-500 dark:text-[#c9c9c9]">
                        Carbon certificate
                      </p>
                    </div>

                    {Object.entries(nft["rewards"]).map((item, key) => {
                      return (
                        <div
                          key={"view_" + key}
                          className="flex justify-between border-t-[1px] dark:border-t-[#333] items-center py-2 dark:text-[#c9c9c9]"
                        >
                          <p className="text-left flex-1 font-normal text-slate-700 dark:text-[#c9c9c9]">
                            {
                              basePlans?.find((_) => _.plan_id == item[0])[
                                "title"
                              ]
                            }
                          </p>
                          <p className="text-left flex-1 text-slate-700 dark:text-[#c9c9c9] font-bold">
                            {item[1]}{" "}
                            <span className="font-light text-sm"> CWAR</span>
                          </p>
                          <p className="text-left flex-1 text-slate-700 dark:text-[#c9c9c9] font-bold">
                            {item[1] / 250}{" "}
                            <span className="font-light text-sm"> CC</span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#ffffff50] rounded-[25px]  app-shadow sm:mx-40 sm:mt-8 dark:bg-[#0d0d0d50]">
              <h2 className="text-2xl p-8 pb-2 dark:text-[#c9c9c9]">
              About
              </h2>
              {/* <Tabs
                tabs={["Attributes", "About", "Details"]}
                setActiveTab={(tabIndex) => setActiveTab(tabIndex)}
                activeTab={activeTab}
              /> */}

              {/* TAB ONE */}

              {/* {activeTab == 0 && (
                <div className="flex flex-wrap p-8">
                  {["Background", "Career", "This"].map((attrib, key) => {
                    return (
                      <div
                        key={"view_tab_" + key}
                        className="bg-[#EBFFF6] p-4 border-[1px] border-blue-200 dark:border-[#464646] rounded-lg mr-2 sm:px-6 dark:bg-[#46464650] dark:text-[#c9c9c9]"
                      >
                        <p className="text-blue-400 text-center">{attrib}</p>
                        <p className="text-lg text-center">NAME</p>
                        <p className="text-sm text-center opacity-50">
                          9% have this trait
                        </p>
                      </div>
                    );
                  })}
                </div>
              )} */}
              {/* TAB TWO */}
              {/* {activeTab == 1 && ( */}
                <div className="p-8 pt-0">
                  <p className="text-slate-600 text-sm dark:text-[#c9c9c9] ">
                    {nft.about}
                  </p>
                </div>
              {/* )} */}

              {/* TAB TWO */}
              {/* {activeTab == 2 && (
                <div className="p-8 pt-0 sm:w-[60%] text-slate-700 dark:text-[#c9c9c9]">
                  <div className="flex justify-between items-center py-2">
                    <p className="text-left flex-1 font-normal ">
                      Contract Address
                    </p>
                    <p className="text-right flex-1 font-normal ">
                      0xsdsd85f4sds8ds
                    </p>
                  </div>

                  <div className="flex justify-between items-center py-2">
                    <p className="text-left flex-1 font-normal ">
                      Contract Address
                    </p>
                    <p className="text-right flex-1 font-normal ">
                      0xsdsd85f4sds8ds
                    </p>
                  </div>

                  <div className="flex justify-between items-center py-2">
                    <p className="text-left flex-1 font-normal ">
                      Contract Address
                    </p>
                    <p className="text-right flex-1 font-normal ">
                      0xsdsd85f4sds8ds
                    </p>
                  </div>
                </div>
              )} */}
            </div>
          </>
        )}
      </div>

      {/* BUY NFT MODAL */}
      <Modal
        visible={showConfirmModal}
        onSubmit={() => transfer?.()}
        title={`Buy NFT - ${nft?.name}`}
        onClose={() => {
          setshowConfirmModal();
          setProgress();
        }}
        onSubmitTitle="Confirm"
        loading={progress || isLoading}
      >
        <div className="p-6 block md:min-w-[600px]">
          <div className="flex items-center">
            <div className="rounded-[30px] m-auto">
              <img
                className="rounded-2xl"
                src={`/thumbs/${thumb_name}.png`}
              />
            </div>
            <div className="p-2 ml-8  w-[50%]">
              <p className="text-1xl text-slate-400 mt-2 font-light">
                CURRENCY
              </p>
              <p className="text-2xl font-bold border-b-2">
                {user?.default_payment}
              </p>
              <p className="text-1xl text-slate-400 mt-2 font-light">PRICE</p>
              <p className="text-2xl font-bold border-b-2">${nft?.price}</p>
              <p className="text-1xl text-slate-400 mt-2 font-light">
                {user?.default_payment}
              </p>
              <div className="flex items-center">
                <img
                  className="rounded-2xl w-5 h-5 my-2 mr-2"
                  src={`/coins/${user?.default_payment ?? "USDT"}.png`}
                />
                <p className="text-2xl font-bold">
                  {((nft?.price / baseCurrencyValue) + (nft?.price / baseCurrencyValue)*0.1/100).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
