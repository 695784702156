import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Header, DropDown, Modal, Button, Tabs, Switch } from "../components";
import "./style.css";
import StakingDetails from "./components/StakingDetails";
import { getErrorFromContract } from "../service/helper";
import {
  STAKE_PLANS,
  USDT_ADDRESS,
  UTILS_ADDRESS,
  NFT_ADDRESS,
  FRAC_ADDRESS,
} from "../config";
import axios from "../axios";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
} from "wagmi";
import useStore from "../store/AppContext";
import CWARFRACTIONAL from "../abi/CWARFRACTIONAL.json";
import CWARNFT from "../abi/CWARNFT.json";
import CWARUTILS from "../abi/CWARUTILS.json";

export default function FractionalizeNFT() {
  const [errors, setErrors] = useState({});
  const [messege, setMessege] = useState({
    type: "error",
    message: null,
  });

  const [vals, setVals] = useState({});
  const [mynfts, setMynfts] = useState();
  const { address, isConnecting, isDisconnected } = useAccount();
  const {
    baseNfts,
    basePlans,
    user,
    setMessege: stateSetMessege,
    message: stateMessage,
    setShowNftAddModal,
    showNftAddModal,
    setProccess: setProccessOnStore,
  } = useStore();
  const [progress, setProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [hasNftApproval, setHasNftApproval] = useState(false);
  const [showStakeConfirm, setShowStakeConfirm] = useState(false);
  const [accept, setAccept] = useState(false);
  const [showApprovalConfirm, setShowApprovalConfirm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    _getMyNfts();
  }, [address, showNftAddModal]);

  const _getMyNfts = () => {
    setLoading(true);
    axios.get(`/user/mynfts/${address}`).then((res) => {
      // // console.log('data mynfts:', res.data)
      if (res.data.status) {
        if (res.data.data.length == 0) {
          setMessege({ ...messege, message: "No NFT Balance!", tab: 0 });
        }
        setMynfts(res.data.data);
      } else {
        setMynfts();
      }
      setLoading(false);
    });
  };

  // GET IS ALREADY APPROVED ACCOUNT
  const { data: hasAlreadyApproved } = useContractRead({
    address: NFT_ADDRESS,
    abi: CWARNFT,
    functionName: "isApprovedForAll",
    args: [address, UTILS_ADDRESS],
  });
  // // console.log('hasAlreadyApproved--->', hasAlreadyApproved)

  // FractionalizeNFT
  const {
    config: configFractionalizeNFT,
    error: errorFractionalizeNFT,
    refetch,
  } = usePrepareContractWrite({
    enabled:
      vals?.nft?.nft_id &&
      vals?.shareAmount &&
      (hasNftApproval || hasAlreadyApproved)
        ? true
        : false,
    // enabled: false,
    address: UTILS_ADDRESS,
    abi: CWARUTILS,
    functionName: "fractionalizeNFT",
    // args: [vals?.nft?.nft_id, vals?.nft?.baseNft, vals?.period],
    args: [vals?.nft?.nft_id, vals?.shareAmount],
  });

  const {
    data: fractionalizingData,
    isLoading: fractionalizingLoading,
    isSuccess: fractionalizingSuccess,
    write: fractionalize,
  } = useContractWrite({
    ...configFractionalizeNFT,
    onSuccess: async (data) => {
      // console.log('Stake Success', data)
      //fractionalize
      const trx = {
        contract: UTILS_ADDRESS,
        type: "fractionalizing",
        transactionHash: data.hash,
        nft_id: vals?.nft?.nft_id,
        base_nft_id: vals?.nft?.base_nft_id,
        name: baseNfts[vals?.nft.base_nft_id - 1]["name"],
        series: baseNfts[vals?.nft.base_nft_id - 1]["current_series"],
        from: address,
        to: UTILS_ADDRESS,
        isFnft: true,
        amount: vals?.shareAmount,
        sponsor_id: user?.sponsor_id,
        user_id: user?.user_id,
        wallet_address: address,
        timestamp: Math.floor(Date.now() / 1000),
        status: "pending",
      };

      // console.log('trx ////// ', trx)
      await axios
        .post("user/add_transaction", trx)
        .then((res) => {
          // console.log(res.data)
        })
        .catch((error) => {
          // console.log('error:', error)
        });

      // console.log('Await for blockchain confirmation...')
      await data.wait();

      axios
        .post(`/user/fractionalize`, { transactionHash: data.hash })
        .then((res) => {
          // console.log('data:', res.data)
          if (res.data.status) {
            setProgress(false);
            // console.log(res.data)
            // console.log('fractionalizing success')
            stateSetMessege({
              title: `Fractionalizing success`,
              render: () => (
                <p>{`#${vals?.nft?.nft_id} ${
                  baseNfts[vals?.nft.base_nft_id - 1]["name"]
                } successfully fractionalized into ${
                  vals?.shareAmount
                } tokens`}</p>
              ),
              type: "success",
            });
            setProccessOnStore(false);
            _getMyNfts();
            setVals();
          } else {
            setProgress(false);
          }
        });
    },
    onError(error) {
      // console.log('Error', error)
      setProgress(false);
      setProccessOnStore(false);
    },
  });

  // APPROVAL
  const { config: configApprovel } = usePrepareContractWrite({
    // enabled: !hasAlreadyApproved,
    address: NFT_ADDRESS,
    abi: CWARNFT,
    functionName: "setApprovalForAll",
    args: [UTILS_ADDRESS, true],
  });

  const {
    data,
    isLoading,
    isSuccess: approvalSuccess,
    write: approve,
  } = useContractWrite({
    ...configApprovel,
    onSuccess: async (data) => {
      await data.wait();
      // console.log('Success approval, going to fractionalizing', data)
      // fractionalize?.()

      // var _fetch = await refetch?.()
      // if (_fetch.isSuccess) {
      // setHasNftApproval(_fetch.data)
      setHasNftApproval(true);
      setShowStakeConfirm(true);
      // }
      setProgress(true);
    },
    onError(error) {
      // console.log('Error', error)
      setProgress(false);
    },
  });

  const _adminMint = () => {
    if (progress) return;
    setErrors({});

    if (fractionalizingSuccess && stateMessage) {
      navigate(`/profile`);
      return;
    }

    var _errors = {};
    if (!vals?.nft) {
      Object.assign(_errors, { nft: "NFT is required" });
      setErrors(_errors);
      return;
    }

    if (!vals?.shareAmount) {
      var _error = "Number of Fractions is required";
      Object.assign(_errors, { shareAmount: _error });
      setErrors(_errors);
      return;
    }

    setProgress(true);
    if (!hasAlreadyApproved) {
      // approve?.()
      setShowApprovalConfirm({ type: "nft", show: true });
    } else {
      // console.log('going to fractionalize...')
      // fractionalize?.()
      setShowStakeConfirm(true);
    }
  };

  // defractionalizeNFT FNFT

  // APPROVAL
  const { config: configdefractionalizeNFT, error: errorDefractionalizeNFT } =
    usePrepareContractWrite({
      enabled: activeTab == 1 && vals?.nft?.isFnft == true ? true : false,
      address: UTILS_ADDRESS,
      abi: CWARUTILS,
      functionName: "defractionalizeNFT",
      args: [vals?.nft?.nft_id],
    });

  const {
    data: dataDefractionalizeNFT,
    isLoading: loadingDefractionalizeNFT,
    isSuccess: defractionalizeNFTSuccess,
    write: defractionalizeNFT,
    // isError
  } = useContractWrite({
    ...configdefractionalizeNFT,
    onSuccess: async (data) => {
      const trx = {
        contract: UTILS_ADDRESS,
        type: "burning F-token",
        transactionHash: data.hash,
        nft_id: vals?.nft?.nft_id,
        base_nft_id: vals?.nft?.base_nft_id,
        name: baseNfts[vals?.nft.base_nft_id - 1]["name"] + " F-Token",
        series: baseNfts[vals?.nft.base_nft_id - 1]["current_series"],
        from: address,
        to: "0x0000000000000000000000000000000000000000",
        isFnft: true,
        amount: vals?.nft?.amount,
        sponsor_id: user?.sponsor_id,
        user_id: user?.user_id,
        wallet_address: address,
        timestamp: Math.floor(Date.now() / 1000),
        status: "pending",
      };

      await axios
        .post("user/add_transaction", trx)
        .then((res) => {
          // console.log(res.data)
        })
        .catch((error) => {
          // console.log('error:', error)
        });

      // console.log('Await for blockchain confirmation...')
      await data.wait();

      axios
        .post(`/user/defractionalize`, { transactionHash: data.hash })
        .then((res) => {
          // console.log('data:', res.data)
          if (res.data.status) {
            setProgress(false);
            // console.log(res.data)
            setProccessOnStore(false);
            // console.log('defractionalizing success')
            stateSetMessege({
              title: `F-NFT defractionalizing success`,
              render: () => (
                <p>{`NFT #${vals?.nft?.nft_id} successfully defractionalized`}</p>
              ),
              type: "success",
            });
            _getMyNfts();
            setVals();
          } else {
            stateSetMessege({
              title: `F-NFT defractionalizing failed`,
              render: () => <p>{res.data.message}</p>,
              type: "error",
            });
            setProgress(false);
          }
        });
    },
    onError(error) {
      setProgress();
      // console.log('Error', error)
      setProccessOnStore(false);
    },
  });
  // console.log("--------------isError--------->", isError);
  // console.log("--------------VALS--------->", vals);

  // Contract Error handling
  useEffect(() => {
    var ERROR = getErrorFromContract(
      errorFractionalizeNFT || errorDefractionalizeNFT
    );
    setMessege({
      type: "error",
      message: ERROR?.replace("execution reverted: ", ""),
      tab: errorFractionalizeNFT ? 0 : 1,
    });
  }, [errorFractionalizeNFT, errorDefractionalizeNFT]);

  // console.log(
  //   'errorFractionalizeNFT',
  //   errorFractionalizeNFT,
  //   errorDefractionalizeNFT,
  // )

  var thumb_name = baseNfts && baseNfts[vals?.nft?.base_nft_id - 1]?.name?.replace(" ", "-").toLowerCase();
  return (
    <>
      <Outlet />
      <div
        id="sidebar"
        className="bg-light_bg dark:bg-dark_bg pb-40 min-h-[100vh] bg-cover bg-no-repeat"
      >
        <Header title="Fractionalize NFT" />
        <div className="flex justify-center">
          <div className="backdrop-blur-md rounded-[25px] app-shadow text-lg text-slate-700 bg-[#ffffff20] px-10 pb-10 mt-20 dark:bg-[#0d0d0d50] dark:text-[#c9c9c9]">
            <div className="flex justify-around">
              <button className="flex" onClick={() => setActiveTab(0)}>
                <h3
                  className={`p-2 py-8 font-bold text-center text-xl mr-4 ${
                    activeTab == 0 ? "text-[#0E7FB8]" : "text-slate-400"
                  } `}
                >
                  {activeTab == 0 && (
                    <div>
                      <span className="absolute flex h-3 w-3 translate-x-[-20px] translate-y-[7px]">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-[#0E7FB8]"></span>
                      </span>
                    </div>
                  )}
                  Fractionalize NFT
                </h3>
              </button>

              <button className="flex relative" onClick={() => setActiveTab(1)}>
                <h3
                  className={`p-2 py-8 font-bold text-center text-xl ${
                    activeTab == 1 ? "text-[#0E7FB8]" : "text-slate-400"
                  } `}
                >
                  {activeTab == 1 && (
                    <div>
                      <span className="absolute flex h-3 w-3  translate-x-[-20px] translate-y-[7px]">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-[#0E7FB8]"></span>
                      </span>
                    </div>
                  )}
                  De-fractionalize NFT
                </h3>
              </button>
            </div>
            {activeTab == 0 && (
              <div className="flex justify-center mt-10 w-full">
                <div className="w-full">
                  <h3 className="font-bold">Select your NFT</h3>
                  <DropDown
                    _key="my_nfts_"
                    placeholder="Select"
                    data={
                      mynfts
                        ? mynfts
                            .filter(
                              (_) => _.isFnft == false && _.is_staked == false
                            )
                            .map((_) => ({
                              value: _.nft_id,
                              title: `#${_.nft_id} - ${_.name}`,
                            }))
                        : []
                    }
                    onChage={(val) => {
                      setVals({
                        ...vals,
                        nft: mynfts.filter((_) => _.nft_id == val)[0],
                      });
                      delete errors["nft"];
                    }}
                    error={errors?.nft}
                    loading={loading}
                  />

                  {/* ADD NFT */}
                  {address && (
                    <div className="text-sm text-blue-600 cursor-pointer float-right flex my-2">
                      <span className="material-symbols-outlined text-sm">
                        add
                      </span>
                      <p onClick={() => setShowNftAddModal(true)}>
                        Add your NFT
                      </p>
                    </div>
                  )}

                  {errors?.nft && (
                    <p className="text-xs text-red-600">* {errors.nft}</p>
                  )}

                  <h3 className="font-bold mt-10">Number of Fractions</h3>
                  <input
                    placeholder="500"
                    className="app-shadow w-full p-3 my-2 rounded-lg focus:outline-none focus:ring focus:ring-slate-300 dark:bg-[#2e2e2e] dark:text-[#c9c9c9]"
                    onChange={(e) => {
                      if (e.target.value < 2) {
                        var error = `Fractions should be more than 1`;
                        setErrors({ shareAmount: error });
                      } else {
                        setErrors({});
                      }
                      setVals({
                        ...vals,
                        shareAmount: e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1"),
                      });
                    }}
                    value={vals?.shareAmount ?? ""}
                    type="text"
                  />
                  {errors?.shareAmount && (
                    <p className="text-xs text-red-600">
                      * {errors.shareAmount}
                    </p>
                  )}

                  {messege.message && messege.tab == 0 && (
                    <p
                      className={`text-orange-600 bg-orange-100 px-5 py-2 text-sm rounded-lg mt-4`}
                    >
                      {messege.message}
                    </p>
                  )}

                  <Button
                    onClick={() => _adminMint()}
                    className="my-10 py-2"
                    loading={progress || fractionalizingLoading}
                    title={
                      progress || fractionalizingLoading
                        ? "proccessing..."
                        : fractionalizingSuccess == true && stateMessage
                        ? "NFT Fractionalized"
                        : "Fractionalize"
                    }
                    type={
                      progress || fractionalizingLoading
                        ? "warn"
                        : fractionalizingSuccess && stateMessage
                        ? "success"
                        : "info"
                    }
                    disabled={Object?.keys(errors).length !== 0}
                  />
                  <p className="text-xs text-center">
                    Privacy Policy and Terms of Service apply.
                  </p>
                </div>
              </div>
            )}

            {/* De-fractionalizeNFT */}
            {activeTab == 1 && (
              <div className="flex justify-center mt-10 w-full">
                <div className="w-full">
                  <h3 className="font-bold">Select your NFT</h3>
                  <DropDown
                    _key="my_nfts_"
                    placeholder="Select"
                    data={
                      mynfts
                        ? mynfts
                            .filter(
                              (_) => _.isFnft == true && _.is_staked == false
                            )
                            .map((_) => ({
                              value: _.nft_id,
                              title: `#${_.nft_id} - ${
                                _.isFnft ? _.name + " F-Token" : _.name
                              } ${_.amount > 1 ? "(" + _.amount + ")" : ""}`,
                            }))
                        : []
                    }
                    onChage={(val) => {
                      setVals({
                        ...vals,
                        nft: mynfts.filter((_) => _.nft_id == val)[0],
                      });
                      delete errors["nft"];
                    }}
                    error={errors?.nft}
                    loading={loading}
                  />

                  <div className="flex justify-between">
                    {errors?.fnft ? (
                      <p className="text-xs text-red-600">{errors?.fnft}</p>
                    ) : (
                      <p></p>
                    )}

                    {/* ADD NFT */}
                    {address && (
                      <div className="text-sm text-blue-600 cursor-pointer float-right flex my-2">
                        <span className="material-symbols-outlined text-sm">
                          add
                        </span>
                        <p onClick={() => setShowNftAddModal(true)}>
                          Add your NFT
                        </p>
                      </div>
                    )}
                  </div>

                  {messege.message && messege.tab == 1 && (
                    <p
                      className={`text-orange-600 bg-orange-100 px-5 py-2 text-sm rounded-lg mt-4`}
                    >
                      {messege.message}
                    </p>
                  )}

                  <Button
                    onClick={() => {
                      if (progress || loadingDefractionalizeNFT) return;

                      if (defractionalizeNFTSuccess && stateMessage) {
                        navigate(`/profile`);
                        return;
                      }

                      setErrors({});
                      var _errors = {};
                      if (!vals?.nft) {
                        Object.assign(_errors, { fnft: "F-NFT is required" });
                        setErrors(_errors);
                        return;
                      }
                      defractionalizeNFT?.();
                      setProccessOnStore(true);
                    }}
                    className="my-10 py-2"
                    loading={progress || loadingDefractionalizeNFT}
                    title={
                      progress || loadingDefractionalizeNFT
                        ? "proccessing..."
                        : defractionalizeNFTSuccess == true && stateMessage
                        ? "NFT defractionalized success"
                        : "De-fractionalize"
                    }
                    type={
                      progress || loadingDefractionalizeNFT
                        ? "warn"
                        : defractionalizeNFTSuccess && stateMessage
                        ? "success"
                        : "info"
                    }
                  />
                  <p className="text-xs text-center">
                    Privacy Policy and Terms of Service apply.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        {showStakeConfirm && (
          <Modal
            // visible={showStakeConfirm}
            visible={showStakeConfirm}
            onSubmit={() => {
              fractionalize?.();
              setShowStakeConfirm(false);
              setProccessOnStore(true);
            }}
            title="Confirm fractionalizing"
            onClose={() => {
              setShowStakeConfirm(false);
              setProgress(false);
            }}
            onSubmitTitle="Confirm"
            buttonDisabled={!accept}
            buttonType="warn"
          >
            {showStakeConfirm && baseNfts && (
              <div className="block">
                <div className="flex items-center">
                  <div className="m-auto">
                    <img
                      className="rounded-[30px]"
                      // src={baseNfts[vals?.nft.base_nft_id - 1]?.url}
                      src={`/thumbs/${thumb_name}.png`}
                    />
                  </div>
                  <div className="p-2 ml-8  w-[50%]">
                    <p className="text-1xl text-slate-400 mt-2 font-light">
                      NFT
                    </p>
                    <p className="text-2xl font-bold border-b-2 dark:border-[#00000090]">
                      {vals?.nft.name}
                    </p>

                    <p className="text-1xl text-slate-400 mt-2 font-light">
                      NUMBER OF FRACTIONS
                    </p>
                    <p className="text-2xl font-bold border-b-2 dark:border-[#00000090]">
                      {vals?.shareAmount}
                    </p>

                    <p className="text-1xl text-slate-400 mt-2 font-light">
                      NFT ID
                    </p>
                    <p className="text-2xl font-bold ">#{vals?.nft.nft_id}</p>
                  </div>
                </div>

                <div className="sm:flex items-center dark:bg-[#00000060] bg-[#ffffff60] mt-8 rounded-lg p-4 justify-between">
                  <p className="m-4 sm:w-[70%] w-[90%] text-justify">
                    When considering fractionalizing an NFT, it's important to
                    proceed with care and understand the implications.
                    Fractionalizing involves dividing the ownership of your NFT
                    into smaller, tradable fractions, enabling shared ownership
                    and potential liquidity. On our platform, you have the
                    option to fractionalize your NFT, allowing others to invest
                    in it. It's crucial to be aware that once fractionalized,
                    regaining sole ownership requires utilizing our
                    de-fractionalization option. Please consider the potential
                    consequences and make an informed decision before proceeding
                    with fractionalization.
                  </p>

                  <Switch
                    enabled={accept}
                    onClick={() => {
                      setAccept(!accept);
                    }}
                    enabledTitle="Accepted"
                    disabledTitle={`⠀Accept⠀`}
                    className={accept ? "" : "border-2 border-[#f84444]"}
                  />
                </div>

                {messege?.messege && (
                  <p className="text-black p-2 border-2 my-2 text-lg border-orange-200">
                    {messege.messege}
                  </p>
                )}
              </div>
            )}
          </Modal>
        )}

        {/* Confirm Approval */}
        <Modal
          visible={showApprovalConfirm?.show}
          onSubmit={() => {
            if (showApprovalConfirm?.type == "nft") {
              setShowApprovalConfirm();
              approve?.();
            }
            // if (showApprovalConfirm?.type == 'fnft') {
            //   setShowApprovalConfirm()
            //   approveFNFT()
            // }
          }}
          title="Confirm Approval?"
          onClose={() => {
            setShowApprovalConfirm();
            setProgress(false);
          }}
          onSubmitTitle="Confirm"
          buttonType="warn"
        >
          <div className="p-6 block md:min-w-[600px]">
            <div className="flex items-center">
              <span className="material-symbols-outlined text-8xl dark:white text-[#f56918] mx-6">
                warning
              </span>
              <p className="">
                Allow access to and transfer your NFTs from the
                Carbon War collection? {"\n"}
                You're granting access to all your Carbon War NFTs, including any you might
                own in the future. This permission is required to allow for Fractionalizing on a platform,
                this approval can be revoked when you are no longer fractionalizing.
              </p>
            </div>
            {messege?.messege && (
              <p className="text-black p-2 border-2 my-2 text-lg border-orange-200">
                {messege.messege}
              </p>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
}
